import React, { useState, useEffect, useRef } from 'react'
import { Trans, useTranslation } from 'react-i18next'

const languages = [
  { code: 'en', language: 'En' },
  { code: 'mn', language: '普' }
]

const LanguageSelector = () => {
  const { i18n } = useTranslation()
  const [showDropdown, setShowDropdown] = useState(false)

  useEffect(() => {
    // Check if language preference exists in storage
    const storedLanguage = localStorage.getItem('language')
    if (
      storedLanguage &&
      languages.find((lng) => lng.code === storedLanguage)
    ) {
      i18n.changeLanguage(storedLanguage)
    } else localStorage.setItem('language', 'en')
  }, [])
  const langRef = useRef()
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (
        langRef.current &&
        !langRef.current.contains(event.target)
      ) {
        // Clicked outside the notification, close it
        setShowDropdown(false)
      }
    
    }

    document.addEventListener('mousedown', handleOutsideClick)

    return () => {
      // Cleanup: Remove the event listener when the component is unmounted
      document.removeEventListener('mousedown', handleOutsideClick)
    }
  }, [])
  const changeLanguageHandler = (code) => {
    i18n.changeLanguage(code)
    // Store selected language in localStorage
    localStorage.setItem('language', code)
    setShowDropdown(false)
  }

  return (
    <div className='relative' ref={langRef}>
      <button
        type='button'
        className={`flex items-center  hover:bg-white-300 focus:ring-4 focus:outline-none focus:ring-white-100 rounded-sm `}
        onClick={() => setShowDropdown(!showDropdown)}
      >
        <svg
          className='h-6 w-6 text-black'
          viewBox='0 0 24 24'
          fill='none'
          stroke='currentColor'
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        >
          {' '}
          <circle cx='12' cy='12' r='10' />
          <line x1='2' y1='12' x2='22' y2='12' />
          <path d='M12 2a15.3 15.3 0 0 1 4 10 15.3 15.3 0 0 1-4 10 15.3 15.3 0 0 1-4-10 15.3 15.3 0 0 1 4-10z' />
        </svg>
        {/* {i18n.language} */}
      </button>
      {showDropdown && (
        <div className='absolute z-10 right-0 mt-2 bg-white border border-white-200 shadow-md rounded-lg '>
          {languages.map((lng) => (
            <button
              type='button'
              className={`block w-full text-left px-4 py-2 ${
                lng.code === i18n.language
                  ? 'text-black-800 bg-gray-200'
                  : 'text-black-600 hover:text-white-800 hover:bg-white-200'
              } dark:text-white dark:hover:text-black-200 dark:hover:bg-white-700`}
              key={lng.code}
              onClick={() => changeLanguageHandler(lng.code)}
            >
              {lng.language}
            </button>
          ))}
        </div>
      )}
    </div>
  )
}

export default LanguageSelector
