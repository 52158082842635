import React from "react";

const NameComp = ({ Heading, Value }) => {
  return (
    <p className='border-blue-600 border border-dashed rounded-md p-2 text-xs font-medium flex gap-3 min-w-max'>
      <span className='text-gray-500'>{Heading} </span>
      <div class="group relative inline-block">
        <div class="w-32 overflow-hidden whitespace-nowrap text-ellipsis">
          {Value}
        </div>
        <span class="absolute hidden group-hover:block group-hover:right-0  bg-gray-800 text-white text-xs z-10 rounded p-2 mt-1 w-max">
          {Value}
        </span>
      </div>
    </p>
  )
}
export default NameComp