import axios from 'axios'
import React, { useEffect, useMemo, useState, useRef } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import UrgentOrderBuyerForm from '../../components/UrgentOrderBuyerComp'
import UserProfile from '../../components/UserProfile'
import { DatefilterFn, ValidateAPI, formatDate, updateActionHistory } from '../../utils/Utils'
import BasicTable from '../../components/BasicTable'
import Loading from '../../components/Loading'
import { useTranslation } from 'react-i18next'
import i18next from 'i18next'
import { formatDateFit } from './BuyerRequestDetails'

const ViewDetail = ({ request }) => {
  const navigate = useNavigate()
  console.log('data received in viewDetail comp: ', request)
  return (
    <p
      className='hover:underline cursor-pointer'
      onClick={() =>
        navigate(
          `/buyer/urgent_orders/request_details/${request?.idopenrequest}`,
          { state: request }
        )
      }
    >
      View Detail
    </p>
  )
}

export default function UrgentOrderBuyerSide() {
  const searchBoxRef = useRef(null)
  const [data, setData] = useState()
  const [vendorList, setVendorList] = useState()
  const [vendorName, setVendorObject] = useState()
  const [formDataList, setFormDataList] = useState([])
  const [open, setOpen] = useState(false)
  const [searchValue, setSearch] = useState('')
  const [user, setUser] = useState(JSON.parse(localStorage.getItem('userData')))
  const [loading, setLoading] = useState(false)
  const { t } = useTranslation()
  const {
    UrgentInquiry ,
    Home,
    BuyerUrgentInquiry,
    NoDataFound,
    SubmitOrderList,
    SearchSupplier
  } = t('UrgentOrderBuyerPage')
  const headers = {
    CR: t('UrgentOrderBuyerPage.CR'),
    SUPPLIERNAME: t('UrgentOrderBuyerPage.SUPPLIERNAME'),
    DESCRIPTION: t('UrgentOrderBuyerPage.DESCRIPTION'),
    QUANTITY: t('UrgentOrderBuyerPage.QUANTITY'),
    SKU: t('UrgentOrderBuyerPage.SKU'),
    UNITPRICE: t('UrgentOrderBuyerPage.UNITPRICE'),
    EXTENDEDPRICE: t('UrgentOrderBuyerPage.EXTENDEDPRICE'),
    DC: t('UrgentOrderBuyerPage.DC'),
    REQSW: t('UrgentOrderBuyerPage.REQSW'),
    STATUS: t('UrgentOrderBuyerPage.STATUS'),
    LASTUPDATED: t('UrgentOrderBuyerPage.LASTUPDATED'),
    VIEWDETAILS: t('UrgentOrderBuyerPage.VIEWDETAILS'),
    // REQSW: t('POList.REQSW'),
    CHANGEDSW: t('POList.CHANGEDSW'),
    ORGSW: t('POList.ORGSW'),
    CURRSW: t('POList.CURRENTSW'),
  }
  const columns = useMemo(
    () => [
      {
        header: headers.CR,
        accessorKey: 'idopenrequest',
        cell: ({ row }) => (
          <p
            className='underline cursor-pointer text-blue-500'
            onClick={() =>
              navigate(
                `/buyer/urgent_orders/request_details/${row?.original.idopenrequest}`,
                { state: row?.original }
              )
            }
          >
            {row?.original?.idopenrequest}
          </p>
        )
        ,filterFn: (row, id, value) => {
          return value.includes(row.getValue(id))
        }
      },
      {
         header: headers.SUPPLIERNAME,
         accessorKey: 'supplierName' ,
         filterFn: (row, id, value) => {
          return value.includes(row.getValue(id))
        }
        },
      {
        header: headers.SKU,
        accessorKey: 'sku',
        cell: (info) => Number(info?.getValue()),
        filterFn: (row, id, value) => {
          return value.includes(row.getValue(id))
        }
      },
      {
        header: headers.DESCRIPTION,
        accessorKey: 'message',
        filterFn: (row, id, value) => {
          return value.includes(row.getValue(id))
        }
      },
      {
        header: headers.CHANGEDSW,
        accessorKey: 'shipweek',
        filterFn: (row, id, value) => {
          return value.includes(row.getValue(id))
        }
      },
      {
        header: headers.ORGSW,
        accessorKey: 'orignalShipWeek',
        filterFn: (row, id, value) => {
          return value.includes(row.getValue(id))
        }
      },
      {
        header: headers.CURRSW,
        accessorKey: 'currentShipWeek',
                filterFn: (row, id, value) => {
          return value.includes(row.getValue(id))
        }
      },
      {
        header: headers.QUANTITY,
        accessorKey: 'quantity'
      },
      { header: headers.UNITPRICE, accessorKey: 'unitPrice' },
      { header: headers.EXTENDEDPRICE, accessorKey: 'extendedPrice' },
      { 
        header: headers.DC,
         accessorKey: 'dcValue' ,
         filterFn: (row, id, value) => {
          return value.includes(row.getValue(id))
        }
        },
      // {
      //   header: headers.REQSW,
      //   accessorKey: 'shipweek',
      //   filterFn: (row, id, value) => {
      //     return value.includes(row.getValue(id))
      //   }
      // },

      { 
        header: headers.STATUS, 
        accessorKey: 'supplierResponse',
        filterFn: (row, id, value) => {
          return value.includes(row.getValue(id))
        }},
      {
        header: headers.LASTUPDATED,
        accessorKey: 'date',
        cell: (info) =>
          info?.getValue() !== null ? formatDateFit(info?.getValue()) : '',
          filterFn:DatefilterFn
      }
      // {
      //   header: "VIEW DETAILS",
      //   isPlaceholder: true,
      //   enableSorting: false,
      //   cell: (info) => <ViewDetail request={info?.row?.original} />,
      // },
    ],
    [headers]
  )

  const navigate = useNavigate()
  useEffect(() => {
    // getVendorList()
    urgentPO()
  }, [])
  useEffect(() => {
    // getVendorList()
    searchVendorORSKU(searchValue)
  }, [searchValue])
  //get all vendor List
  function getVendorList() {
    axios
      .get('${process.env.REACT_APP_API_DOMAIN}:8088/po/vendors')
      .then((response) => {
        console.log(response)

        setVendorList(response?.data)
      })
      .catch((error) => {
        if(error.response.status===401 || error.response.status===403){
          navigate('/login-error')
        }
      })
  }
  //Get Urgent PO Order List by buyer id
  const urgentPO = () => {
    setLoading(true)
    axios
      .get(
        `${process.env.REACT_APP_API_DOMAIN}:8088/po/UrgentPosBYBuyer?createdBy=${user?.fullName}`,ValidateAPI
      )
      .then((response) => {
        console.log(response)
        setData(response?.data)
        setLoading(false)
      })
      .catch((error) => {
        setLoading(false)
        if(error.response.status===401 || error.response.status===403){
          navigate('/login-error')
        }
      })
  }
  //get particular vendor name
  const handleChange = (e) => {
    const vendorPayload = vendorList.find(
      (data) => data.vendorOracleId === e.target.value
    )
    setVendorObject(vendorPayload)
    setFormDataList([
      {
        supplierName: vendorPayload?.vendorName,
        supplierNo: vendorPayload?.vendorId,
        createdBy: user?.email,
        sku: '',
        quantity: '',
        unitPrice: '',
        dcValue: '',
        shipweek: '',
        message: '',
        extendedPrice: '',
        skuDesc: '',
        UPC: ''
      }
    ])
  }
  const handleSubmit = (event) => {
    event.preventDefault()
    // Handle form submission
    const payload = formDataList.map((obj, i) => {
      return {
        ...obj,
        extendedPrice: formDataList[i]?.unitPrice * formDataList[i]?.quantity
      }

      return obj
    })
    if (
      formDataList?.some((e) => e?.shipweek === '') ||
      formDataList?.some((e) => e?.dcValue === '') ||
      formDataList?.some((e) => e?.message === '')
    )
      return alert(
        i18next.t('PopUpMessage.Pleaseenterallrequiredvaluesbeforesubmitting')
      )
    axios
      .post(
        `${process.env.REACT_APP_API_DOMAIN}:8088/po/UrgentPoCreate`,
        payload,ValidateAPI
      )
      .then((res) => {
        console.log('reeeeeees', res)
        alert(i18next.t('PopUpMessage.OrderSubmitted'))
        // updateActionHistory("Created", res?.data)
        setVendorObject()
        setFormDataList([])
        setSearch('')
        urgentPO()
      })
      .catch((err) => {
        alert(i18next.t('PopUpMessage.PleaseoutFillouttherequiredfield'))
      })
    console.log(payload)
  }

  const handleSupplierData = (data) => {
    setVendorObject(data)
    console.log(data)
    setSearch(data?.displayName)
    setFormDataList([
      {
        supplierName: data?.vendorName,
        supplierNo: data?.vendorOracleId,
        createdBy: user?.fullName,
        hFTUserId: user?.hftOracleId,
        sku: '',
        quantity: '',
        unitPrice: '',
        dcValue: '',
        shipweek: '',
        message: '',
        extendedPrice: '',
        skuDescription: '',
        uPC: ''
      }
    ])
    setOpen(false)
  }
  const searchVendorORSKU = (value) => {
    axios
      .get(
        `${process.env.REACT_APP_API_DOMAIN}:8088/po/VendorNameOrSKUBySearchString?searchString=${value}`,ValidateAPI
      )
      .then((response) => {
        console.log(response)

        setVendorList(response?.data)
      })
      .catch((error) => {
        alert(error?.response?.data)
      })
  }
  const vendorListRef = useRef(null)
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (
        vendorListRef.current &&
        !vendorListRef.current.contains(event.target)
      ) {
        // Clicked outside the notification, close it
        setOpen(false)
      }
    }

    document.addEventListener('mousedown', handleOutsideClick)

    return () => {
      // Cleanup: Remove the event listener when the component is unmounted
      document.removeEventListener('mousedown', handleOutsideClick)
    }
  }, [])

  return (
    <main className='w-full flex flex-col gap-2 justify-start items-start p-4 font-pop'>
      {/* Header Section */}
      <div className='w-full justify-between flex items-center'>
        <div className=''>
          <p className='text-xl font-medium'>{UrgentInquiry}</p>
        </div>
        {/* <UserProfile /> */}
      </div>
      {/* BreadCrumb */}
      <div className='w-full text-xs flex justify-start items-center gap-1 font-medium text-gray-600'>
        <p className='flex gap-1 items-center justify-center'>
          <span
            className='hover:underline cursor-pointer'
            onClick={() => navigate('/buyer')}
          >
            {Home}
          </span>
          <span>{'>'}</span>
        </p>

        <p className='flex gap-1 items-center justify-center'>
          <span>{BuyerUrgentInquiry} </span>
        </p>
      </div>
      {/* Supplier Dropdown and getting all supplier  */}
      {/* <div className="flex gap-3 w-full my-10">
        <div className="w-auto">
          <label
            for="supplier_name"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Select a Supplier Name
          </label>
          <select
            id="supplier_name"
            name="supplier_name"
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            onChange={handleChange}
          >
            <option selected>Choose a Name</option>
            <input type="text" name="suppliername" />
            {vendorList?.map((data) => {
              return (
                <option value={data?.vendorOracleId}>{data?.vendorName}</option>
              );
            })}
          </select>
        </div>
      </div> */}

      {/* New Dropdown for Supplier with Search Input */}
      <div className='w-[40%] font-normal  relative group'>
        <div
          className='w-full p-1 cursor-pointer flex items-center justify-between rounded border border-gray-500 text-gray-700 text-sm hover:ring-blue-600 hover:border-blue-600'
          onClick={() => {
            setOpen(!open)
          }}
        >
          <input
            type='text'
            autoComplete='off'
            value={searchValue}
            onChange={(e) => setSearch(e.target.value.toUpperCase())}
            onFocus={() => {setSearch('');setFormDataList([])}}
            placeholder={SearchSupplier}
            className='placeholder:text-gray-700 p-2 outline-none w-full'
          />
          <span className='mx-2'>{'|'}</span>
          <svg
            className='w-4 h-4 text-gray-800 mr-2 dark:text-white'
            aria-hidden='true'
            xmlns='http://www.w3.org/2000/svg'
            fill='none'
            viewBox='0 0 20 20'
          >
            <path
              stroke='currentColor'
              strokeLinecap='round'
              strokeLinejoin='round'
              strokeWidth='2'
              d='m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z'
            />
          </svg>
        </div>
        {open && (
          <div
            ref={vendorListRef}
            className='bg-white mt-1 overflow-y-auto scroll-smooth z-20 border border-gray-500 rounded-sm max-h-52 absolute w-full'
            style={{ top: '100%', left: 0 }}
          >
            {vendorList?.length !== 0 ? (
              vendorList?.map((data) => {
                return (
                  <div
                    className={`p-2 text-gray-500 text-sm hover:bg-[#0059A8] hover:text-white`}
                    onClick={() => handleSupplierData(data)}
                  >
                    {data?.displayName}
                  </div>
                )
              })
            ) : (
              <h1 className='text-sm text-gray-500 text-center p-2'>{NoDataFound}</h1>
            )}
          </div>
        )}
      </div>
      {vendorName ? (
        <UrgentOrderBuyerForm
          vendorName={vendorName}
          formDataList={formDataList}
          setFormDataList={setFormDataList}
        />
      ) : (
        ''
      )}
      {vendorName?.vendorOracleId ? (
        <button
          onClick={handleSubmit}
          className='bg-[#0059A8] p-2 rounded-lg text-sm  text-white'
        >
          {SubmitOrderList}
        </button>
      ) : (
        ''
      )}
      {data?.length !== 0 ? (
        <div className=' w-full'>
          {/* <h3 className="my-4">
            Show
            <select className="mx-2 bg-white border px-2 py-1 rounded-sm">
              <option>10</option>
              <option>15</option>
              <option>20</option>
            </select>{" "}
            entries
          </h3> */}

          {loading ? (
            <Loading />
          ) : (
            <div className='w-full'>
              <BasicTable tableData={data} columns={columns} />
            </div>
          )}
          {/* <div className="w-full border border-gray-300 text-xs">
            <table className="w-full border border-gray-300">
              <thead className="divide-x-[1px] bg-[#e0ecfc] divide-gray-300 border-[#ccd9e4] border">
                <tr className="divide-x-[1px] bg-[#e0ecfc] divide-gray-300 border-[#ccd9e4] border">
                  <th className="font-medium px-2 py-3">Supplier Name</th>
                  <th className="font-medium px-2 py-3">SKU</th>
                  <th className="font-medium px-2 py-3 ">Qty</th>
                  <th className="font-medium px-2 py-3 ">Unit Price</th>
                  <th className="font-medium px-2 py-3 ">Extended Price</th>
                  <th className="font-medium px-2 py-3 ">DC Value</th>
                  <th className="font-medium px-2 py-3 ">
                    Requested ship week
                  </th>
                  <th className="font-medium px-2 py-3 ">Description</th>
                  <th className="font-medium px-2 py-3 ">Status</th>
                  <th className="font-medium px-2 py-3 ">CRID</th>
                  <th className="font-medium px-2 py-3">Last Updated</th>
                </tr>
              </thead>
              <tbody>
                {data?.map((data, i) => {
                  return (
                    <tr
                      className={`${
                        i % 2 !== 0 ? "bg-[#ecedf2]" : "bg-[#ffffff] "
                      } text-center divide-x-[1px] divide-gray-300 border border-gray-300 text-gray-600`}
                      key={i}
                    >
                      <td className="font-medium px-2 py-3">
                        {data.supplierName}
                      </td>
                      <td className="font-medium px-2 py-3">{data.sku}</td>
                      <td className="font-medium px-2 py-3">
                        {data?.quantity}
                      </td>
                      <td className="font-medium px-2 py-3">
                        {data.unitPrice}
                      </td>
                      <td className="font-medium px-2 py-3">
                        {data.extendedPrice}
                      </td>
                      <td className="font-medium px-2 py-3">{data.dcValue}</td>
                      <td className="font-medium px-2 py-3">{data.shipweek}</td>
                      <td className="font-medium px-2 py-3">{data.message}</td>
                      <td className="font-medium px-2 py-3">
                        {data.supplierResponse}
                      </td>
                      <td className="font-medium px-2 py-3">
                        <p
                          className="hover:underline cursor-pointer"
                          onClick={() =>
                            navigate(
                              `/buyer/urgent_orders/request_details/${data.idopenrequest}`,
                              { state: data }
                            )
                          }
                        >
                          {data.idopenrequest}
                        </p>
                      </td>
                      <td className="px-3 py-2">{formatDate(data.date)}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div> */}
        </div>
      ) : (
        <h1 className='text-center p-2 bg-gray-200 text-black'>
          {NoDataFound}
        </h1>
      )}
    </main>
  )
}
