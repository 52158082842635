import axios from 'axios'
import { APIReducer, inititalState } from './Reducer'
import { useContext, createContext, useReducer, useEffect, useState } from 'react'
import { ValidateAPI } from '../utils/Utils'
import { set } from 'lodash'

const APICALL = createContext()

const APICONTEXT = ({ children }) => {
  const user = JSON.parse(localStorage.getItem('userData'))
  const [state, dispatch] = useReducer(APIReducer, inititalState)

  function SearchBuyer(role = '') {
    axios
      .get(
        `${process.env.REACT_APP_API_DOMAIN}:8088/api/search-buyers?searchstring=&roleType=${role}`, ValidateAPI
      )
      .then((responce) => {
        const data = responce?.data?.map((buyer) => ({
          value: buyer.BUYER_DETAIL,
          label: buyer.BUYER_DETAIL
        }))
        dispatch({
          type: 'BUYERCOUNT',
          payload: data
        })
      })
      .catch((err) => {
        // return alert("some thing issue ", err)
      })
  }

  function SearchSKU(role='') {
    axios
      .get(
        `${process.env.REACT_APP_API_DOMAIN}:8088/api/get-all-sku?searchString=${role}`,ValidateAPI
      )
      .then((responce) => {
        const data = responce?.data?.map((SKU) => ({
          value: SKU.sku,
          label: SKU.sku
        }))
        dispatch({
          type: 'SKUCOUNT',
          payload: data
        })
      })
      .catch((err) => {
        // return alert("some thing issue ", err)
      })
  }


  function searchVendorORSKU(role = '') {
    axios
      .get(
        `${process.env.REACT_APP_API_DOMAIN}:8088/po/VendorNameOrSKUBySearchString?searchString=${role}`, ValidateAPI
       
      )
      .then((response) => {
        const data = response?.data?.map((vendor) => ({
          value: vendor.vendorOracleId,
          label: vendor.vendorName
        }))
        dispatch({
          type: 'VENDORCOUNT',
          payload: data
        })
      })
      .catch((err) => {
        // alert("some thing issue ", err)
      })
  }
  function getFilterData(userid, tableName) {
    axios
      .get(
        `${process.env.REACT_APP_API_DOMAIN}:8088/filter/preference/getFilterDetails?hftUserId=${userid}&tableName=${tableName}`,
        ValidateAPI
      )
      .then((response) => {
        dispatch({
          type: 'FILTERDATA',
          payload: response?.data
        })
      })
      .catch((err) => {
        // alert("some thing issue ", err)
      })
  }
  function addFilterData(payload) {
    axios
      .post(`${process.env.REACT_APP_API_DOMAIN}:8088/filter/preference/saveOrUpdate`, payload, ValidateAPI)
      .then((response) => {
        console.log(response);
        
      })
      .catch((error) => {
        console.log(error);

      })
  }
  function SearchSKU(role='') {
    axios
      .get(
        `${process.env.REACT_APP_API_DOMAIN}:8088/api/get-all-sku?searchString=${role}`,ValidateAPI
      )
      .then((responce) => {
        const data = responce?.data?.map((SKU) => ({
          value: SKU.sku,
          label: SKU.sku
        }))
        dispatch({
          type: 'SKUCOUNT',
          payload: data
        })
      })
      .catch((err) => {
        // return alert("some thing issue ", err)
      })
  }
  return (
    <APICALL.Provider
      value={{
        state,
        searchVendorORSKU,
        SearchBuyer,
        SearchSKU,
        getFilterData,
        addFilterData,
        SearchSKU
        // updateFilterArray
      }}
    >
      {children}
    </APICALL.Provider>
  )
}

export default APICONTEXT

export const CommonAPICall = () => {
  return useContext(APICALL)
}
