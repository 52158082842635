import React, { useState, useEffect, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import { formatDateFit } from '../buyer/BuyerRequestDetails'
import {
  DatefilterFn,
  ValidateAPI,
  handleAcceptExpediteDeferRequests,
  handlePODetailsClick,
  handleRejectExpediteDeferDivertRequest,
  reasonCodesEN,
  reasonCodesMN
} from '../../utils/Utils'
import ConfirmTable from '../../components/ConfirmTable'
import Loading from '../../components/Loading'
import { EditedRowState } from '../../LineContext/Context'
import { useTranslation } from 'react-i18next'
import i18next from 'i18next'
import PopupModal from '../../components/PopupModal'
import PopupBanner from '../../components/PopupBanner'
import ReasonCodeList, { ReasonCodeView } from '../../components/ReasonCodeList'

const Divert = () => {
  const [requestData, setRequestData] = useState([])
  const user = JSON.parse(localStorage.getItem('userData'))
  const [loading, setLoading] = useState(false)
  const [selectedRowArray, setSelectedRowArray] = useState([])
  const navigate = useNavigate()
  const [showRejectModal, setShowRejectModal] = useState(false)
  const [reasonCodeValue, setreasonCodeValue] = useState('')
  const [submitting, setSubmitting] = useState(false)
  const [rowSelection, setRowSelection] = useState({})
  const [showDivertAcceptModal, setShowDivertAcceptModal] = useState(false)
  const [showChangeRequestBanner, setShowChangeRequestBanner] = useState(false)
  const [showRejectRequestBanner, setShowRejectRequestBanner] = useState(false)
  const [poRow, setPoRow] = useState('')
  const [crIdResponse, setCrIdResponse] = useState('')
  const [editCrIdResponse, setEditCrIdResponse] = useState('')
  const [editPoRow, setEditPORow] = useState('')
  const { t } = useTranslation()
  const [validSw, setValidSw] = useState(false)
  const tableId = 'Divertable' // Define tableId here
  const {
    DivertRequests,
    Home,
    Submitting_Load,
    Pleaseselectreasoncodeforrejectingtheorder,
    Selectareason,
    Reject,
    Cancel
  } = t('DivertPage')
  const {
    state: { editArray },
    dispatch
  } = EditedRowState()

  // Access translated strings outside useMemo
  const headers = {
    CR: t('DivertPage.CR'),
    PONUMBER: t('DivertPage.PONUMBER'),
    SKU: t('DivertPage.SKU'),
    STATUS: t('DivertPage.STATUS'),
    REQUESTTYPE: t('DivertPage.REQUESTTYPE'),
    REQUESTEDBY: t('DivertPage.REQUESTEDBY'),
    CREATEDATE: t('DivertPage.CREATEDATE'),
    UPDATEDATE: t('DivertPage.UPDATEDATE'),
    CRSTATUS: t('DivertPage.CRSTATUS'),
    VENDORRESPONSE: t('DivertPage.VENDORRESPONSE'),
    // REQSW: t('DivertPage.REQSW'),
    CHANGEDSW: t('POList.CHANGEDSW'),
    CURRENTSW: t('DivertPage.CURRENTSW'),
    ORGSW: t('ConfirmPOpage.ORGSW'),
    DC: t('DivertPage.DC'),
    REQDC: t('DivertPage.REQDC')
  }
  const columns = useMemo(
    () => [
      {
        header: headers.CR,
        accessorKey: 'id_open_request',
        cell: ({ row }) =>
          row?.original?.request_type === 'URGENT' ? (
            <p
              className='underline cursor-pointer text-blue-500'
              onClick={() =>
                navigate(
                  `/vendor/urgent_orders/request_details/${row?.original.id_open_request}`,
                  { state: row?.original }
                )
              }
            >
              {row?.original?.id_open_request}
            </p>
          ) : (
            <p
              className='underline cursor-pointer text-blue-500'
              onClick={() =>
                navigate(
                  `/vendor/po_change_requests/request_details/${row?.original.id_open_request}`,
                  { state: row?.original }
                )
              }
            >
              {row?.original?.id_open_request}
            </p>
          )
      },
      {
        header: headers.PONUMBER,
        accessorKey: 'po_number',
        cell: (info) => {
          return (
            <p
              className='underline cursor-pointer text-blue-500'
              onClick={() => handlePODetailsClick('vendor', info?.getValue())}
            >
              {info?.getValue()}
            </p>
          )
        }
      },
      {
        header: headers.SKU,
        accessorKey: 'sku'
      },
      {
        header: headers.DC,
        accessorKey: 'original_dc'
      },
      {
        header: headers.REQDC,
        accessorKey: 'requested_dc'
      },
      { header: headers.CHANGEDSW, accessorKey: 'requested_ship_week' },
      {
        header: headers.ORGSW,
        accessorKey: 'original_ship_week',
        filterFn: (row, id, value) => {
          return value.includes(row.getValue(id))
        }
      },
      {
        header: headers.CURRENTSW,
        accessorKey: 'current_ship_week'
      },
      { header: headers.VENDORRESPONSE, accessorKey: 'supplier_response' },
      { header: headers.CRSTATUS, accessorKey: 'status' },
      { header: headers.REQUESTTYPE, accessorKey: 'request_type' },
      { header: headers.REQUESTEDBY, accessorKey: 'created_by' },
      {
        header: headers.CREATEDATE,
        accessorKey: 'created_at',
        cell: (info) =>
          info?.getValue() !== null ? formatDateFit(info?.getValue()) : '',
        filterFn: DatefilterFn
      }
      // {
      //   header: headers.UPDATEDATE,
      //   accessorKey: 'updated_at',
      //   cell: (info) =>
      //     info?.getValue() !== null ? formatDateFit(info?.getValue()) : ''
      // },
    ],
    [headers]
  )

  const closeDivertAcceptModal = () => {
    setShowDivertAcceptModal(false)
  }

  const openDivertAcceptModal = () => {
    setShowDivertAcceptModal(true)
  }
  const closePopUpModal = () => {
    // setPoRow(false)
    setShowRejectRequestBanner(false)
    setShowChangeRequestBanner(false)
  }

  // const handleAcceptRequest = () => {
  //   setSubmitting(true)
  //   setShowRejectModal(true)
  //   handleAcceptExpediteDeferRequests(
  //     setRequestData,
  //     selectedRowArray,
  //     setSubmitting,
  //     setShowRejectModal
  //   )
  //   closeDivertAcceptModal()
  // }
  const handleAcceptRequest = () => {
    setSubmitting(true)
    setShowRejectModal(true)

    handleAcceptExpediteDeferRequests(
      setRequestData,
      selectedRowArray,
      setSubmitting,
      setShowRejectModal,
      // setStopUpdating,
      (success, data) => {
        if (success) {
          if (data) {
            const crIdResponse = data.map((item) => item.id_open_request)
            const poRow = data.map((item) => item.po_number)
            setCrIdResponse(crIdResponse)
            setPoRow(poRow)
          }
          setShowChangeRequestBanner(true)
          fetchAllRequests()
        }
      }
    )

    closeDivertAcceptModal()
    // fetchAllRequests()
    // setShowChangeRequestBanner(true)
  }

  const fetchAllRequests = () => {
    setLoading(true)
    axios
      .get(
        `${process.env.REACT_APP_API_DOMAIN}:8088/api/get-supplier-cr-divert-data?vendorNumber=${user?.supplierId}`,
        ValidateAPI
      )
      .then((response) => {
        console.log('response from FCR: ', response?.data)

        if (response?.data?.[0]?.Message) return setRequestData([])
        setRequestData(response?.data)
        setLoading(false)
      })
      .catch((error) => {
        console.log(`error while FRC: `, error)
        setLoading(false)
        if (error.response.status === 401 || error.response.status === 403) {
          navigate('/login-error')
        }
      })
  }

  useEffect(() => {
    fetchAllRequests()
  }, [showChangeRequestBanner, showRejectRequestBanner])

  useEffect(() => {
    const handleClickOutside = () => {
      setShowChangeRequestBanner(false)
      setShowRejectRequestBanner(false)
    }
    if (showChangeRequestBanner || showRejectRequestBanner) {
      window.addEventListener('click', handleClickOutside)
    }
    return () => {
      window.removeEventListener('click', handleClickOutside)
    }
  }, [showChangeRequestBanner, showRejectRequestBanner])

  useEffect(() => {
    if (selectedRowArray?.length > 0) {
      setValidSw(false); // Hide the error message as soon as an order is selected
    }
  }, [selectedRowArray]);

  return (
    <div className='w-full flex flex-col gap-6 justify-start items-start p-4'>
      <div className='w-full justify-between flex items-center'>
        <div className=''>
          <p className='text-xl'>{DivertRequests}</p>
        </div>
        {/* <UserProfile /> */}
      </div>
      <div className='w-full text-xs flex justify-start items-center gap-1 font-medium text-gray-600'>
        <p className='flex gap-1 items-center justify-center'>
          <span
            className='hover:underline cursor-pointer'
            onClick={() => navigate('/vendor')}
          >
            {Home}
          </span>
          <span>{'>'}</span>
        </p>
        <p className='flex gap-1 items-center justify-center'>
          <span>{DivertRequests}</span>
        </p>
      </div>
      <p className="text-yellow-600 text-sm italic my">
      <span className="font-bold text-black">NOTE : </span> ""To Propose New DC Click On CR#, Navigate To PO Change Request Details Page.""
</p>
     <div>
     <div className='flex w-full justify-start items-center font-medium text-xs gap-3'>
        <button
          className='px-3 rounded-md bg-green-500 text-white h-[32px]'
          onClick={() => {
            if (selectedRowArray?.length > 0) {
              openDivertAcceptModal()
              setValidSw(false)
            }else{
              setValidSw(true)
            }
          }}
          
        >
          <span>
            {selectedRowArray?.length > 1 ? t('Accept') : t('Accept')}
          </span>
        </button>

        <PopupModal
          isOpen={showDivertAcceptModal}
          message={i18next.t('PopUpMessage.Doyouwanttoaccepttheserequests')}
          onCancel={closeDivertAcceptModal}
          cancelText={i18next.t('AcceptModal.Cancel')}
          onConfirm={handleAcceptRequest}
          confirmText={i18next.t('AcceptModal.Confirm')}
        />

        <button
          className='px-3 rounded-md bg-red-500 text-white h-[32px]'
          onClick={() => {
            if (selectedRowArray?.length > 0) {
              setShowRejectModal(true)
              setValidSw(false)
            }else{
              setValidSw(true)
            }
          }}
         
        >
          {selectedRowArray?.length > 1 ? t('Reject') : t('Reject')}
        </button>
      </div>
      {validSw && (
          <p className='text-red-500 text-xs mt-2'>Please Select a PO.</p>
        )}
     </div>

      {loading ? (
        <div className='w-full min-h-[300px] flex justify-center items-center'>
          <Loading />
        </div>
      ) : (
        <div className='w-full overflow-x-auto'>
          <ConfirmTable
            tableId={tableId}
            tableData={requestData}
            columns={columns}
            selection={setSelectedRowArray}
            updateRowSelectionProp={setRowSelection}
            rowSelectionProp={rowSelection}
          />
        </div>
      )}
      <dialog
        open={showRejectModal}
        className='p-3 inset-0 fixed z-70 bg-black bg-opacity-50 w-full min-h-screen'
      >
        <div className='w-full min-h-screen p-4 flex justify-center items-center'>
          {submitting ? (
            <div className='sm:w-[400px] w-[280px] bg-white p-4 rounded-md flex flex-col gap-6'>
              <Loading type={Submitting_Load} />
            </div>
          ) : (
            <div className='sm:w-[400px] w-[280px] bg-white p-4 rounded-md flex flex-col gap-6'>
              <div className='w-full flex justify-end items-center'>
                <button
                  className='border-2 border-gray-300 p-2 rounded-md'
                  onClick={() => {
                    setShowRejectModal(false)
                    setreasonCodeValue('')
                  }}
                >
                  ❌
                </button>
              </div>
              <div className='w-full flex justify-center items-center mt-2 text-center'>
                <p className='text-sm font-medium'>
                  {Pleaseselectreasoncodeforrejectingtheorder}
                </p>
              </div>
              {/* This is to show reasonCodeValue once user selected from list  */}
              <ReasonCodeView
                reasonCodeValue={reasonCodeValue}
                setreasonCodeValue={setreasonCodeValue}
              />
              {/* View End */}

              {/*  ReasonCode List Component to view all list and select*/}
              <ReasonCodeList
                reasonCodeValue={reasonCodeValue}
                setreasonCodeValue={setreasonCodeValue} type='Divert'/>
              {/* End Component */}
              <div className='w-full flex justify-center items-center gap-4 mt-4'>
                <button
                  className='text-xs font-medium w-[80px] rounded-md h-[38px] text-white bg-red-500 outline-none'
                  onClick={() => {
                    handleRejectExpediteDeferDivertRequest(
                      setRequestData,
                      selectedRowArray,
                      reasonCodeValue,
                      setShowRejectModal,
                      setreasonCodeValue,
                      setSubmitting,
                      (success, data) => {
                        if (success) {
                          if (data) {
                            const editCrIdResponse = data.map(
                              (item) => item.id_open_request
                            )
                            const editPoRow = data.map((item) => item.po_number)
                            console.log('editCrIdResponse:', editCrIdResponse)
                            console.log('editpo response', editPoRow)
                            setEditPORow(editPoRow)
                            setEditCrIdResponse(editCrIdResponse)
                          }
                          setShowRejectRequestBanner(true)
                          fetchAllRequests()
                        }
                      }
                    )
                  }}
                >
                  {Reject}
                </button>
                {/* <PopupBanner
        isOpen={showRejectRequestBanner}
               
        message={"Orders Rejected  successfully"}
        onCancel={closePopUpModal}
        text={poRow}
        text1={crIdResponse}
      /> */}
                <button
                  className='text-xs font-medium w-[80px] rounded-md h-[38px] text-white bg-gray-500 outline-none'
                  onClick={() => {
                    setShowRejectModal(false)
                    setreasonCodeValue('')
                  }}
                >
                  {Cancel}
                </button>
              </div>
            </div>
          )}
        </div>
      </dialog>
      <PopupBanner
        isOpen={showChangeRequestBanner}
        message={'Divert Orders Submitted Successfully'}
        onCancel={closePopUpModal}
        text={poRow}
        text1={crIdResponse}
      />
      <PopupBanner
        isOpen={showRejectRequestBanner}
        message={'Divert Orders Rejected  Successfully'}
        onCancel={closePopUpModal}
        text={editPoRow}
        text1={editCrIdResponse}
      />
    </div>
  )
}

export default Divert
