export const editedRowsReducer = (state, action) => {
  switch (action.type) {
    case 'ADD_TO_LIST':
      console.log('Add to list has been triggered & payload: ', action.payload)
      return {
        ...state,
        editArray: [...state.editArray, { ...action.payload }]
      }
    case 'REMOVE_FROM_LIST':
      console.log(
        'remove from list has been triggered & payload: ',
        action.payload
      )
      return {
        ...state,
        editArray: state.editArray.filter(
          (c) =>
            // c.PO_SKU !== action.payload?.SKU &&
            // c.PO_NUMBER !== action.payload?.PO_NUMBER
            c?.PO_SKU!==action.payload?.PO_SKU
        )
      }
    case 'UPDATE_SW_VALUE':
      console.log('update array value: ', action.payload)
      console.log(
        'filter operation: ',
        state.editArray.filter((c) => c.id_open_request === action.payload.id)
      )
      return {
        ...state,
        editArray: state.editArray.filter((c) =>
          c.id_open_request === action.payload.id
            ? (c.requested_ship_week = action.payload.sw)
            : c.requested_ship_week
        )
      }
    case 'ADD_TO_CR_LIST':
      console.log('Add to list has been triggered & payload: ', action.payload)
      return {
        ...state,
        editArray: [...state.editArray, { ...action.payload }]
      }
    case 'REMOVE_CR_FROM_LIST':
      console.log(
        'remove from list has been triggered & payload: ',
        action.payload
      )
      return {
        ...state,
        editArray: state.editArray.filter(
          (c) => c.id_open_request !== action.payload?.id
        )
      }
    case 'CLEAR_LIST':
      return {
        ...state,
        editArray: []
      }
    default:
      return state
  }
}
