import React, { useEffect, useLayoutEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import axios from 'axios'
import { ValidateAPI } from '../../utils/Utils'
import { CartState } from '../../Context/Context'

const VendorSidebar = () => {
  const navigation = useNavigate()
  const [reqType, setReqTypeData] = useState([])
  const { t } = useTranslation()
  const user = JSON.parse(localStorage?.getItem('userData'))
  const navigate = useNavigate()
  const {
    Dashboard,
    MyOpenPOs,
    POChangeRequests,
    DeferOrders,
    DivertOrders,
    ExpediteOrders,
    POConfirmOrders,
    POReConfirmOrders,
    UrgentInquiry
  } = t('VendorSidebar')

  useLayoutEffect(() => {
    // You can set sidebar translations or perform other side-effects here if needed
  }, [])
  const { state: { updateCount } ,updateDataCount} = CartState()
  // console.log(updateCount);
  const fetchReqTypeData = () => {
    axios
      .get(
        `${process.env.REACT_APP_API_DOMAIN}:8088/api/admin/get-counts-email-table?vendorNumber=${user?.supplierId}`, ValidateAPI
      )
      .then((response) => {
        console.log(response)
        setReqTypeData(response?.data)
      })
      .catch((error) => {
        if (error.response.status === 401 || error.response.status === 403) {
          navigate('/login-error')
        }
      })
  }

  useEffect(() => {
    updateDataCount(user?.supplierId)
  }, [navigate])
  return (
    <div className='min-w-[220px] flex flex-col gap-5 justify-between min-sidebar-screen items-start h-full sticky top-0 bg-blue-100 overflow-y-auto'>
      <div className='w-full flex flex-col gap-3'>
        <div className='w-full flex flex-col justify-start h-full relative items-start gap-4 border-[#E6EDFF] border-r py-5 font-medium'>
          <div
            key={'vendor'}
            className={`w-full justify-between flex text-xs items-center cursor-pointer hover:bg-[#0059A8] group ${window?.location?.pathname === '/vendor' && 'bg-[#0059A8]'
              }`}
            onClick={() => {
              navigation('/vendor')
            }}
          >
            <p
              className={`text-black p-2 pl-4 group-hover:text-white ${window?.location?.pathname === '/vendor' && 'text-white'
                }`}
            >
              {Dashboard}
            </p>
          </div>

          <div
            key={'vendor/open_pos'}
            className={`w-full justify-between flex text-xs items-center cursor-pointer hover:bg-[#0059A8] group ${window?.location?.pathname.includes('vendor/open_pos') &&
              'bg-[#0059A8]'
              }`}
            onClick={() => {
              navigation('/vendor/open_pos')
            }}
          >
            <p
              className={`text-black p-2 pl-4 group-hover:text-white ${window?.location?.pathname.includes('vendor/open_pos') &&
                'text-white'
                }`}
            >
              {MyOpenPOs}
            </p>
          </div>

          <div
            key={'vendor/po_change_requests'}
            className={`w-full justify-between flex text-xs items-center cursor-pointer hover:bg-[#0059A8] group ${window?.location?.pathname.includes('vendor/po_change_requests') && 'bg-[#0059A8]'
              }`} style={{ marginBottom: '-15px' }}
            onClick={() => {
              navigation('/vendor/po_change_requests')
            }}
          >
            <p
              className={`text-black p-2 pl-4 group-hover:text-white ${window?.location?.pathname.includes('vendor/po_change_requests') && 'text-white'}`}

            >
              {POChangeRequests}
            </p>

          </div>

          {/* Vertical line and markers for submenus */}
          <div className='relative pl-4 ' style={{ width: '100%', paddingRight: '8px' }}>
            {/* Vertical line */}
            <div className='absolute left-4 top-0 bottom-0 w-0.5 bg-gray-600' style={{ height: 'calc(100% - 15px)' }}></div>

            {/* Submenu items */}
            <div className='relative mt-2' >
              {/* Expedite Orders */}
              <div
                key={'vendor/expedite-request'}
                className={`w-full flex text-xs items-center cursor-pointer hover:bg-[#0059A8] group mb-1 relative
                ${window?.location?.pathname.includes('vendor/expedite-request') &&
                  'bg-[#0059A8]'
                  }`}
                onClick={() => {
                  navigation('/vendor/expedite-request')
                }}
              >
                {/* Marker for Expedite Orders */}
                <div className='absolute left-0 top-1/2 transform -translate-y-1/2 w-[calc(100%-92%)] h-0.5 bg-gray-600'></div>
                <div className='flex items-center justify-between w-full'>
                  <p
                    className={`text-black p-2 pl-4 group-hover:text-white ${window?.location?.pathname.includes('vendor/expedite-request')
                      &&
                      'text-white'
                      }`}
                  >
                    {ExpediteOrders}
                  </p>
                  <div className='bg-[#0059A8] text-white items-center justify-center p-1 h-6 text-center rounded-lg'>
                    {updateCount[4]?.TOTAL_COUNT}
                  </div>
                </div>
              </div>
              {/* Defer Orders */}
              <div
                key={'vendor/defer-request'}
                className={`w-full flex text-xs items-center cursor-pointer hover:bg-[#0059A8] group mt-1 relative
                ${window?.location?.pathname.includes('vendor/defer-request') &&
                  'bg-[#0059A8]'
                  }`}
                onClick={() => {
                  navigation('/vendor/defer-request')
                }}
              >
                {/* Marker for Defer Orders */}
                <div className='absolute left-0 top-1/2 transform -translate-y-1/2 w-[calc(100%-92%)] h-0.5 bg-gray-600'></div>
                <div className='flex items-center justify-between w-full'>
                  <p
                    className={`text-black p-2 pl-4 group-hover:text-white ${window?.location?.pathname.includes('vendor/defer-request')
                      &&
                      'text-white'
                      }`}
                  >
                    {DeferOrders}
                  </p>
                  <div className='bg-[#0059A8] text-white items-center justify-center p-1 h-6 text-center rounded-lg'>
                    {updateCount[1]?.TOTAL_COUNT}
                  </div>

                </div>
              </div>

              {/* Divert Orders */}
              <div
                key={'vendor/divert-request'}
                className={`w-full flex text-xs items-center cursor-pointer hover:bg-[#0059A8] group relative
                ${window?.location?.pathname.includes('vendor/divert-request') &&
                  'bg-[#0059A8]'
                  }`
                }
                onClick={() => {
                  navigation('/vendor/divert-request')
                }}
              >
                {/* Marker for Divert Orders */}
                <div className=' absolute left-0 top-1/2 transform -translate-y-1/2 w-[calc(100%-92%)] h-0.5 bg-gray-600'></div>
                <div className='flex items-center justify-between w-full'>
                  <p
                    className={`text-black p-2 pl-4 group-hover:text-white ${window?.location?.pathname.includes('vendor/divert-request')
                      &&
                      'text-white'
                      }`}
                  >
                    {DivertOrders}
                  </p>
                  <div className='bg-[#0059A8] text-white items-center justify-center p-1 h-6 text-center rounded-lg'>
                    {updateCount[2]?.TOTAL_COUNT}
                  </div>

                </div>
              </div>


            </div>
          </div>

          <div
            key={'vendor/po_confirm'}
            className={`w-full justify-between flex px-2 text-xs items-center cursor-pointer hover:bg-[#0059A8] group
             ${window?.location?.pathname.includes('vendor/po_confirm') &&
              'bg-[#0059A8]'
              }`}
            onClick={() => {
              navigation('/vendor/po_confirm')
            }}
          >
            <p
              className={`text-black p-2  group-hover:text-white ${window?.location?.pathname.includes('vendor/po_confirm') &&
                'text-white'
                }`}
            >
              {POConfirmOrders}
            </p>
            <div className='bg-[#0059A8] text-white items-center justify-center p-1 h-6 text-center rounded-lg'>
              {updateCount[0]?.TOTAL_COUNT}
            </div>

          </div>

          <div
            key={'vendor/po_reconfirm'}
            className={`w-full justify-between flex text-xs items-center cursor-pointer px-2 hover:bg-[#0059A8] group ${window?.location?.pathname.includes('vendor/po_reconfirm') &&
              'bg-[#0059A8]'
              }`}
            onClick={() => {
              navigation('/vendor/po_reconfirm')
            }}
          >
            <p
              className={`text-black p-2  group-hover:text-white ${window?.location?.pathname.includes('vendor/po_reconfirm') &&
                'text-white'
                }`}
            >
              {POReConfirmOrders}
            </p>
            <div className='bg-[#0059A8] text-white items-center justify-center p-1 h-6 text-center rounded-lg'>
              {updateCount[5]?.TOTAL_COUNT}
            </div>

          </div>

          <div
            key={'vendor/urgent_orders'}
            className={`w-full justify-between flex text-xs px-2 items-center cursor-pointer hover:bg-[#0059A8] group ${window?.location?.pathname.includes('vendor/urgent_orders') &&
              'bg-[#0059A8]'
              }`}
            onClick={() => {
              navigation('/vendor/urgent_orders')
            }}
          >
            <p
              className={`text-black p-2  group-hover:text-white ${window?.location?.pathname.includes('vendor/urgent_orders') &&
                'text-white'
                }`}
            >
              {UrgentInquiry}
            </p>
            <div className='bg-[#0059A8]  text-white items-center justify-center p-1 h-6 text-center rounded-lg'>
              {updateCount[6]?.TOTAL_COUNT}
            </div>

          </div>
        </div>
      </div>
    </div>
  )
}

export default VendorSidebar
