import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import LanguageSelector from "./buyer/Language-selector";
import { useTranslation } from "react-i18next";
import Cookies from 'js-cookie'
import LandingPage from "../components/LandingPage";
const Login = ({ updateUser }) => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { t } = useTranslation()
  const { Signintoyouraccount, Youremail, Passwordplace, Forgotpassword, Signin, Donthaveanaccountyet, Signup, Rememberme } = t("LoginPage")
  const [vendorurl, setvendor] = useState(process.env.REACT_APP_ANOTHER_API_URL + '/auth/oracle?role=vendor')
  const [buyerurl, setbuyer] = useState(process.env.REACT_APP_ANOTHER_API_URL + '/auth/oracle?role=buyer')
  const handleLogin = (email) => {
    const payload = {
      userid: email,
      password: '',
    }
    console.log("login payload", payload);
    const token = Cookies.get('idcs_user_assertion')
    console.log(token);
    axios
      .post(
        `${process.env.REACT_APP_API_DOMAIN}:8088/api/HFTUserLogin`,
        payload,
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
            'Role':Cookies.get('role')
          }
        }

      )
      .then((response) => {
        console.log("login info: ", response?.data);

        localStorage?.setItem("userData", JSON.stringify(response?.data));
        updateUser(response?.data);
        if (response?.data?.roleName === "SUPPLIER_ROLE") {
          let url = sessionStorage.getItem("my_task_url");
          console.log("url from session: ", url);
          if (url) {
            console.log("url if satisfied");
            // window.location.href = url;
            sessionStorage?.removeItem("event_url");
            navigate(url);
          } else {
            navigate("/vendor");
          }
        } else if (response?.data?.roleName === "BUYER_ROLE") {
          navigate("/buyer");
        } else if (response?.data?.roleName === "MANAGER_ROLE" || response?.data?.roleName === "OVERSEAS_ROLE") {
          response.data['roleName'] = "BUYER_ROLE";
          localStorage?.setItem("userData", JSON.stringify(response?.data));
          navigate("/buyer");
        }
        else if (response?.data?.roleName === "ADMIN_ROLE") {
          navigate("/admin");
        }
        // const data = {
        //   "fullName": "HELEN  FRUTOS",
        //   "email": "HFRUTOS@HARBORFREIGHT.COM",
        //   "supplierName": null,
        //   "supplierId": null,
        //   "phoneNumber": null,
        //   "country": null,
        //   "city": null,
        //   "userGroupRole": "3",
        //   "userGroupName": "HFT_ASIA_PECIFIC",
        //   "roleName": "ADMIN_ROLE",
        //   "enabled": "Y",
        //   "vendorContactOrcaleId": null,
        //   "hftOracleId": "1395",
        //   "userType": "H"
        // }
        // localStorage?.setItem("userData", JSON.stringify(data));
        // updateUser(data);
        // navigate("/admin");
      })
      .catch((error) => {
        console.log("error while loggin in: ", error);
        navigate('/login-error')
        // alert(error?.response?.data);
        // const role = Cookies.get('role')
        // const tokenId = Cookies.get('idcs_id_token')
        // localStorage?.removeItem("userData")
        // Cookies.remove('idcs_user_assertion')
        // Cookies.remove('idcs_id_token')
        // Cookies.remove('role')
        // const cookieKeys = Cookies.getJSON()
        // cookieKeys.forEach(key => Cookies.remove(key))
        // window.location.href = `${process.env.IDCS_DOMAIN}:9000/logout?role=${role}&token-id=${tokenId}`
      })
  }

  const getuser = async () => {
    const role = Cookies.get('role')
    try {
      const result = await fetch(
        `${process.env.REACT_APP_ANOTHER_API_URL}/idcs?role=${role}`,
        {
          method: 'GET',
          credentials: 'include'
        }
      )
      const data = await result.json()
      console.log(data?.token?.sub);
      handleLogin(data?.token?.sub)

    }
    catch (err) {
      navigate('/login-error')
    }
  }
  useEffect(() => {
      // // console.log(process.env.REACT_APP_ANOTHER_API_URL);
      if (Cookies.get('idcs_user_assertion')) {
        getuser()
      }
      
    // handleLogin("sjamal") 
    // handleLogin('HFRUTOs')
    // handleLogin('tapas.garg@altumindtech.com')
    // handleLogin("SHENDA12@SHENDA.COM.CN")
    // handleLogin("JASON.DAI@MERIDIANINTL.COM")
    // handleLogin("pdewangan")
    // handleLogin("aris_fam@china-staple.com.tw")
  }, [])
  const headersrole = {
    'Content-Type': 'application/json',
    'Token-ID': Cookies.get('idcs_user_assertion')
  };
  const idcscall = async (role) => {
    const result = await fetch('http://localhost:3000/auth/oracle', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'role': role
      }
    })

  }
  return (
    <LandingPage >
      <a href={vendorurl} className=" bg-[#415da1] border rounded p-3 font-medium text-xs text-white ">Login as Vendor</a>
      <a href={buyerurl} className=" bg-[#415da1] border rounded p-3 font-medium text-xs text-white ">Login as HFT Employee</a>

    </LandingPage>
  );
};
export default Login;
