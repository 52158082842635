import React from "react";
import Select from "react-select";
const SelectList = ({List,defaultName='',handleChangeData,placeholder,isClearable,name=''}) => {
    return (
        <>
            <Select
                // options={options}
                placeholder={placeholder}
                options={List}
                value={defaultName!==''?{
                    value: defaultName,
                    label: defaultName,
                }:'null'}
                name={name}
                onChange={handleChangeData}
                //  isClearable={true}
                 isClearable={isClearable}
                styles={{
                    control: (provided) => ({
                        ...provided,
                        borderColor: 'blue', // Change the border color
                        minWidth: 200,
                        height: 15,
                    }),
                    singleValue: (provided) => ({
                        ...provided,
                        color: 'black', // Change the color of the selected option text
                    }),
                    option: (provided, state) => ({
                        ...provided,
                        backgroundColor: state.isSelected ? 'lightgray' : provided.backgroundColor,
                        color: state.isSelected ? 'black' : provided.color,
                    }),
                    placeholder: (provided) => ({
                        ...provided,
                        color: 'gray', // Change the placeholder color
                    }),
                    menu: (provided) => ({
                        ...provided,
                        zIndex: 9999, // Increase z-index for the menu
                        fontSize: '12px'
                    }),
                    menuPortal: (provided) => ({
                        ...provided,
                        zIndex: 9999, // Increase z-index for the menu portal
                    }),
                    
                }}
            />
           
        </>
    )
}
export default SelectList