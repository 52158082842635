import React, { useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
// import BreadCrumb from "../Components/Breadcrumb/BreadCrumb";
import axios from 'axios'
import UserProfile from '../../components/UserProfile'
import { DatefilterFn, ValidateAPI, downloadCSV, formatDate, updateActionHistory } from '../../utils/Utils'
import { reasonCodesEN, reasonCodesMN } from '../../utils/Utils'
import ReactDatePicker from 'react-datepicker'
import { getWeek } from '../buyer/NewPoList'
import BasicTable from '../../components/BasicTable'
import { useTranslation } from 'react-i18next'
import i18next from 'i18next'
import { formatDateFit } from '../buyer/BuyerRequestDetails'
import ReasonCodeList, { ReasonCodeView } from '../../components/ReasonCodeList'
export default function UrgentOrder() {
  const [data, setData] = useState()
  const [showacpetModel, setShowModel] = useState(false)
  const [showRejectModel, setrejectModel] = useState(false)
  const [acceptDAta, setAcceptData] = useState({})
  const [reasonCodeValue, setreasonCodeValue] = useState('')
  const [date, setDate] = useState(new Date())
  const [user, setUser] = useState(JSON.parse(localStorage.getItem('userData')))
  const [reasoncode, setreasoncode] = useState([])
  const [showAcceptDatePicker, setShowAcceptDatePicker] = useState(false)
  const [requestSW, setRequestSW] = useState(null)
  const navigate = useNavigate()
  //Language translation start

  const { t } = useTranslation()
  const {
    BuyerUrgentInquiry,
    UrgentInquiry,
    Home,
    AreyousureyouwanttoaccepttheOrder,
    SelectShipweek,
    Confirm,
    Cancel,
    Pleaseselectreasoncodeforrejectingtheorder,
    Reject,
    X,
    Selectareason,
    Changetheshipweek,
    SelctedShipWeek
  } = t('VendorUrgentOrder')


  const handlePODetailsClick = (idopenrequest) => {
    return navigate(`/vendor/urgent_orders/request_details/${idopenrequest}`)
  }
  useEffect(() => {
    getUrgentOrder()
  }, [])
  function getUrgentOrder() {
    axios
      .get(
        `${process.env.REACT_APP_API_DOMAIN}:8088/po/UrgentPosBYSupplier?supplierCode=${user?.supplierId}`, ValidateAPI
      )
      .then((response) => {
        console.log(response)
        setData(response?.data)
      })
      .catch((error) => {
        if (error.response.status === 401 || error.response.status === 403) {
          navigate('/login-error')
        }
      })
  }
  const Search = (e) => {
    const value = e.target.value.toUpperCase()
    axios
      .get(
        `${process.env.REACT_APP_API_DOMAIN}:8088/po/poSearchList?searchString=${value}`
      )
      .then((res) => {
        if (e.target.value) {
          setTimeout(() => {
            setData(res?.data)
          }, 1000)
        } else {
          setTimeout(() => {
            setData(res?.data)
          }, 1000)
        }
      })
      .catch((err) => { })
  }
  const AcceptRequest = (action) => {
    const finalpayload = {
      ...acceptDAta,
      status: action,
      vendorSelectedShipweek: requestSW
    }
    console.log(finalpayload)
    axios
      .post(
        `${process.env.REACT_APP_API_DOMAIN}:8088/po/UrgentPoApproveOrReject`,
        finalpayload, ValidateAPI
      )
      .then((res) => {
        updateActionHistory('Accepted', [finalpayload])
        getUrgentOrder()
        setShowModel(false)
        setrejectModel(false)
      })
      .catch((error) => {
        if (error.response.status === 401 || error.response.status === 403) {
          navigate('/login-error')
        }
      })
  }
  const RejectRequest = (action) => {
    if (!reasonCodeValue?.length > 0)
      return alert(i18next.t('VendorPopUp.Pleaseselectareasoncode'))

    const finalpayload = { ...acceptDAta, status: action, reasonCode: reasonCodeValue }
    console.log(finalpayload)
    axios
      .post(
        `${process.env.REACT_APP_API_DOMAIN}:8088/po/UrgentPoApproveOrReject`,
        finalpayload, ValidateAPI
      )
      .then((res) => {
        updateActionHistory('Rejected', [finalpayload])
        getUrgentOrder()
        setShowModel(false)
        setrejectModel(false)
      })
      .catch((error) => {
        if (error.response.status === 401 || error.response.status === 403) {
          navigate('/login-error')
        }
      })
  }

  const handleChangeDate = (date) => {
    setDate(date)
    setRequestSW(getWeek(date))
  }
  const getWeek = (value) => {
    const currentDate = new Date(value)
    const currentYear = currentDate.getFullYear()
    const firstDayOfYear = new Date(currentYear, 0, 1)
    const days = Math.round(
      (currentDate - firstDayOfYear) / (24 * 60 * 60 * 1000)
    )
    const weekNumber = Math.ceil((days + firstDayOfYear.getDay() + 1) / 7)
    return `${weekNumber}.${currentYear % 100}`
  }
  const Action = ({ requestData }) => {
    return requestData?.supplierResponse === 'Awaiting Response' &&
      requestData?.status === 'OPEN' ? (
      <div className='flex gap-2 justify-center items-center'>
        <button
          onClick={() => {
            setShowModel(true)
            setAcceptData(requestData)
          }}
        >
          <svg
            className='w-6 h-6 text-green-600 '
            aria-hidden='true'
            xmlns='http://www.w3.org/2000/svg'
            fill='currentColor'
            viewBox='0 0 20 20'
          >
            <path d='M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z' />
          </svg>
        </button>
        <button
          onClick={() => {
            setrejectModel(true)
            setAcceptData(requestData)
          }}
        >
          <svg
            className='w-6 h-6 text-red-500'
            aria-hidden='true'
            xmlns='http://www.w3.org/2000/svg'
            fill='currentColor'
            viewBox='0 0 20 20'
          >
            <path d='M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 11.793a1 1 0 1 1-1.414 1.414L10 11.414l-2.293 2.293a1 1 0 0 1-1.414-1.414L8.586 10 6.293 7.707a1 1 0 0 1 1.414-1.414L10 8.586l2.293-2.293a1 1 0 0 1 1.414 1.414L11.414 10l2.293 2.293Z' />
          </svg>
        </button>
      </div>
    ) : (
      <div className='flex justify-center items-center'>
        {requestData?.supplierResponse === 'ACCEPTED' && (
          <svg
            className='w-6 h-6 text-green-600 '
            aria-hidden='true'
            xmlns='http://www.w3.org/2000/svg'
            fill='currentColor'
            viewBox='0 0 20 20'
          >
            <path d='M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z' />
          </svg>
        )}
        {(requestData?.status === 'CANCELLED' ||
          requestData?.supplierResponse === 'REJECTED') && (
            <svg
              className='w-6 h-6 text-red-500'
              aria-hidden='true'
              xmlns='http://www.w3.org/2000/svg'
              fill='currentColor'
              viewBox='0 0 20 20'
            >
              <path d='M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 11.793a1 1 0 1 1-1.414 1.414L10 11.414l-2.293 2.293a1 1 0 0 1-1.414-1.414L8.586 10 6.293 7.707a1 1 0 0 1 1.414-1.414L10 8.586l2.293-2.293a1 1 0 0 1 1.414 1.414L11.414 10l2.293 2.293Z' />
            </svg>
          )}
      </div>
    )
  }

  const headers = {
    HFTCR: t('VendorUrgentOrder.HFTCR'),
    SUPPLIERNAME: t('VendorUrgentOrder.SUPPLIERNAME'),
    SKU: t('VendorUrgentOrder.SKU'),
    SKUDESCRIPTION: t('VendorUrgentOrder.SKUDESCRIPTION'),
    QUANTITY: t('VendorUrgentOrder.QUANTITY'),
    UNITPRICE: t('VendorUrgentOrder.UNITPRICE'),
    EXTENDEDPRICE: t('VendorUrgentOrder.EXTENDEDPRICE'),
    REQUESTEDSHIPWEEK: t('VendorUrgentOrder.REQUESTEDSHIPWEEK'),
    // CHANGEDSW: t('POList.CHANGEDSW'),
    CURRENTCONFIRMSHIPWEEK: t('VendorUrgentOrder.CURRENTCONFIRMSHIPWEEK'),
    LASTUPDATED: t('VendorUrgentOrder.LASTUPDATED'),
    ACTION: t('VendorUrgentOrder.ACTION'),
    CRSTATUS: t('VendorUrgentOrder.CRSTATUS'),
    VENDORRESPONSE: t('VendorUrgentOrder.VENDORRESPONSE')
  }

  const column = useMemo(() => [
    {
      header: headers.HFTCR,
      accessorKey: 'idopenrequest',
      cell: (info) => (
        <p
          className='hover:underline cursor-pointer text-blue-500'
          onClick={() => handlePODetailsClick(info?.getValue())}
        >
          {info?.getValue()}
        </p>
      )
    },

    {
      header: headers.SUPPLIERNAME,
      accessorKey: 'supplierName'
    },
    { header: headers.SKU, accessorKey: 'sku' },
    {
      header: headers.SKUDESCRIPTION,
      accessorKey: 'message'
    },
    {
      header: headers.QUANTITY,
      accessorKey: 'quantity'
    },
    {
      header: headers.UNITPRICE,
      accessorKey: 'unitPrice'
    },
    {
      header: headers.EXTENDEDPRICE,
      accessorKey: 'extendedPrice'
    },
    {
      header: headers.VENDORRESPONSE,
      accessorKey: 'supplierResponse'
    },
    {
      header: headers.CRSTATUS,
      accessorKey: 'status'
    },
    {
      header: headers.REQUESTEDSHIPWEEK,
      accessorKey: 'shipweek'
    },
    {
      header: headers.CURRENTCONFIRMSHIPWEEK,
      accessorKey: 'vendorSelectedShipweek'
    },

    {
      header: headers.LASTUPDATED,
      accessorKey: 'date',
      cell: (info) =>
        info?.getValue() !== null ? formatDateFit(info?.getValue()) : '',
      filterFn: DatefilterFn
    },

    {
      header: headers.ACTION,
      accessorKey: '',
      cell: (info) => <Action requestData={info?.row?.original} />
    }
  ])

  return (
    <main className='w-full flex flex-col gap-2 justify-start items-start p-4'>
      {/* Header Section */}
      <div className='w-full justify-between flex items-center'>
        <div className=''>
          <p className='text-xl font-medium'>{UrgentInquiry}</p>
        </div>
        {/* <UserProfile /> */}
      </div>
      {/* BreadCrumb */}
      <div className='w-full text-xs flex justify-start items-center gap-1 font-medium text-gray-600'>
        <p className='flex gap-1 items-center justify-center'>
          <span
            className='hover:underline cursor-pointer'
            onClick={() => navigate('/vendor')}
          >
            {Home}
          </span>
          <span>{'>'}</span>
        </p>

        <p className='flex gap-1 items-center justify-center'>
          <span>{BuyerUrgentInquiry}</span>
        </p>
      </div>

      {/* //Getting all Supplier Data in Table */}
      {data?.length !== 0 && <BasicTable tableData={data} columns={column} />}
      {/* Accept Order Dailog Box*/}
      <dialog
        open={showacpetModel}
        className='p-3 inset-0 fixed z-40 bg-black bg-opacity-50 w-full min-h-screen'
      >
        <div className='w-full min-h-screen p-4 flex justify-center items-center'>
          <div className='sm:w-[400px] w-[280px] bg-white p-4 rounded-md flex flex-col gap-6'>
            <div className='w-full flex justify-end items-center'>
              <button
                className='border-2 border-gray-300 p-2 rounded-md text-sm'
                onClick={() => setShowModel(false)}
              >
                ❌
              </button>
            </div>
            <div className='w-full flex justify-center items-center mt-2 text-center'>
              <p className='text-sm font-medium'>
                {AreyousureyouwanttoaccepttheOrder}
              </p>
            </div>
            <div className='text-xs flex gap-2'>
              <input
                type='checkbox'
                name='date_change_cb'
                id='date_change_cb'
                value={showAcceptDatePicker}
                onChange={(e) => setShowAcceptDatePicker(e?.target?.checked)}
              />
              <label htmlFor='date_change_cb'>{Changetheshipweek}</label>
            </div>
            {showAcceptDatePicker && (
              <div className='flex flex-col gap-3 text-xs w-full'>
                <div className='flex justify-between items-center w-full'>
                  <p>{SelectShipweek}:</p>

                  <ReactDatePicker
                    showWeekNumbers
                    filterDate={(date) => date.getDay() === 1}
                    selected={date}
                    onChange={(date) => handleChangeDate(date)}
                    className='p-1 border border-gray-300 rounded-md'
                    calendarStartDay={1}
                    minDate={new Date()}
                    showYearDropdown
                  />
                </div>
                <div className='w-full flex justify-between items-center'>
                  <p>{SelctedShipWeek}</p>
                  <p>{requestSW}</p>
                </div>
              </div>
            )}
            <div className='w-full flex justify-center items-center gap-4 mt-4'>
              <button
                className='text-xs font-medium w-[80px] rounded-md h-[38px] text-white bg-green-600 outline-none'
                onClick={() => AcceptRequest('APPROVED')}
              >
                {Confirm}
              </button>
              <button
                className='text-xs font-medium w-[80px] rounded-md h-[38px] text-white bg-red-500 outline-none'
                onClick={() => setShowModel(false)}
              >
                {Cancel}
              </button>
            </div>
          </div>
        </div>
      </dialog>
      {/* Accept Order Reject BOx  */}
      <dialog
        open={showRejectModel}
        className='p-3 inset-0 fixed z-40 bg-black bg-opacity-50 w-full min-h-screen '
      >
        <div className='w-full min-h-screen p-4 flex justify-center items-center'>
          <div className='sm:w-[400px] w-[280px] bg-white p-4 rounded-md flex flex-col gap-6'>
            <div className='w-full flex justify-end items-center'>
              <button
                className='border-2 border-gray-300 p-2 rounded-md text-sm'
                onClick={() => {
                  setrejectModel(false)
                  setreasonCodeValue('')
                }}
              >
                ❌
              </button>
            </div>

            <div className='w-full flex justify-center items-center mt-2 text-center'>
              <p className='text-sm font-medium'>
                {Pleaseselectreasoncodeforrejectingtheorder}
              </p>
            </div>
            {/* This is to show reasonCodeValue once user selected from list  */}
            <ReasonCodeView
              reasonCodeValue={reasonCodeValue}
              setreasonCodeValue={setreasonCodeValue} />
            {/* View End */}

            {/*  ReasonCode List Component to view all list and select*/}
            <ReasonCodeList
              reasonCodeValue={reasonCodeValue}
              setreasonCodeValue={setreasonCodeValue} />
            {/* End Component */}

            <div className='w-full flex justify-center items-center gap-4 mt-4'>
              <button
                className='text-xs font-medium w-[80px] rounded-md h-[38px] text-white bg-green-600 outline-none'
                onClick={() => RejectRequest('REJECTED')}
              >
                {Reject}
              </button>
              <button
                className='text-xs font-medium w-[80px] rounded-md h-[38px] text-white bg-red-500 outline-none'
                onClick={() => {
                  setrejectModel(false)
                  setreasonCodeValue('')
                }}
              >
                {Cancel}
              </button>
            </div>
          </div>
        </div>
      </dialog>
    </main>
  )
}
