import { useEffect, useState } from "react"
import { reasonCodesEN, reasonCodesMN,multipleReasonCode } from "../utils/Utils"
import { useTranslation } from "react-i18next"


const ReasonCodeList = ({ reasonCodeValue, setreasonCodeValue,type='common' }) => {
    const { t } = useTranslation()

    const [reason_codes, setReasonCode] = useState([])
    const { ReasonCode, Selectareason } = t('ConfirmPOpage')
    // changed reason code validation for divert confirm and quntitty 29/10/24
    // useEffect(() => {
    //     const handleStorage = () => {
    //         if (window.localStorage?.getItem('language') === 'en') {
    //             setReasonCode(reasonCodesEN)
    //         } else {
    //             setReasonCode(reasonCodesMN)
    //         }
    //     }

    //     window.addEventListener('storage', handleStorage())
    //     return () => window.removeEventListener('storage', handleStorage())
    // }, [window.localStorage?.getItem('language')])
    useEffect(()=>{
        const filteredReasonCode=  multipleReasonCode?.filter((data)=>data?.type===type)      
        setReasonCode(filteredReasonCode)
    },[type])
    return (
        <div className='w-full flex flex-col items-start gap-2'>

            <label
                htmlFor='reason_code_select'
                className='text-xs font-medium'
            >
                {ReasonCode}
            </label>
            <select
                onChange={(e) => setreasonCodeValue(e.target.value)}
                name='reason_code_select'
                id='reason_code_select'
                className='p-2 rounded-md outline-none border border-gray-300 text-xs w-full font-medium'
                value={reasonCodeValue}
            >
                <option value='' disabled>
                    {Selectareason}
                </option>
                {reason_codes?.map((reason) => (
                    <option key={reason?.value} value={reason?.value}>
                        {reason?.value}
                    </option>
                ))}
            </select>

        </div>
    )
}
export default ReasonCodeList

export const ReasonCodeView = ({ reasonCodeValue,setreasonCodeValue }) => {
    return (
        <div className='w-full flex flex-wrap justify-center items-center '>
            {reasonCodeValue && <div
                className='rounded-lg bg-[#4472C4] flex  gap-2 justify-center items-center p-2 text-xs text-white font-medium'
            >
                <span>{reasonCodeValue}</span>
                <span
                    className='cursor-pointer'
                    onClick={() =>
                        setreasonCodeValue('')
                    }
                >
                    X
                </span>
            </div>}
        </div>
    )
}