import React, { useState, useEffect, useMemo } from 'react'
import UserProfile from '../../components/UserProfile'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import { formatDateFit } from '../buyer/BuyerRequestDetails'
import BasicTable from '../../components/BasicTable'
import { DatefilterFn, ValidateAPI, handlePODetailsClick, MultiSelectColumnFilter } from '../../utils/Utils'
import { useTranslation } from 'react-i18next'
import { CommonAPICall } from '../../CommonAPiContext/context'

const ViewDetail = ({ request }) => {
  const navigate = useNavigate()
  console.log('data received in viewDetail comp: ', request)
  return request?.request_type === 'URGENT' ? (
    <p
      className='hover:underline cursor-pointer text-blue-600'
      onClick={() =>
        navigate(
          `/vendor/urgent_orders/request_details/${request?.id_open_request}`,
          { state: request }
        )
      }
    >
      View Detail
    </p>
  ) : (
    <p
      className='hover:underline cursor-pointer text-blue-600'
      onClick={() =>
        navigate(
          `/vendor/po_change_requests/request_details/${request?.id_open_request}`,
          { state: request }
        )
      }
    >
      View Detail
    </p>
  )
}

const VendorRequest = () => {
  const [requestData, setRequestData] = useState([])
  const user = JSON.parse(localStorage.getItem('userData'))
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { POChangeRequests, Home, Loading1 } = t('VendorRequestPage')
  const tableId = 'VendorChangeReqTable'; // Define tableId here
  // const [rowSelection, setRowSelection] = useState({})
  // const updateEditedRows = (updatedRows) => {
  //   setEditedRows(updatedRows)
  // }

  // function selectTableRow(rowId) {
  //   table.getRowById(rowId)?.toggleSelected()
  // }

  // Access translated strings outside useMemo
  const headers = {
    CR: t('VendorRequestPage.CR'),
    PONUMBER: t('VendorRequestPage.PONUMBER'),
    SKU: t('VendorRequestPage.SKU'),
    STATUS: t('VendorRequestPage.STATUS'),
    REQUESTTYPE: t('VendorRequestPage.REQUESTTYPE'),
    REQUESTEDBY: t('VendorRequestPage.REQUESTEDBY'),
    CREATEDATE: t('VendorRequestPage.CREATEDATE'),
    UPDATEDATE: t('VendorRequestPage.UPDATEDATE'),
    CRSTATUS: t('VendorRequestPage.CRSTATUS'),
    VENDORRESPONSE: t('VendorRequestPage.VENDORRESPONSE'),
    SELECTEDSHIPWEEK: t('HFTBuyerChangeRequestsPage.SELECTEDSHIPWEEK'),
    // REQSW: t('VendorRequestPage.REQSW'),
    CHANGEDSW: t('POList.CHANGEDSW'),
    ORGSW: t('POList.ORGSW'),
    CURRSW: t('POList.CURRENTSW'),
    ONTIMETARGET: t('POList.ONTIMETARGET'),
    OPENQUANTITY: t('POList.OPENQUANTITY'),
    PREVIOUSW:t('POList.PREVIOUSW'),
    INVENTORYANALYST:t('POList.INVENTORYANALYST')
  }
  const columns = useMemo(
    () => [
      {
        header: headers.CR,
        accessorKey: 'id_open_request',
        cell: ({ row }) =>
          row?.original?.request_type === 'URGENT' ? (
            <p
              className='underline cursor-pointer text-blue-500'
              onClick={() =>
                navigate(
                  `/vendor/urgent_orders/request_details/${row?.original.id_open_request}`,
                  { state: row?.original }
                )
              }
            >
              {row?.original?.id_open_request}
            </p>
          ) : (
            <p
              className='underline cursor-pointer text-blue-500'
              onClick={() =>
                navigate(
                  `/vendor/po_change_requests/request_details/${row?.original.id_open_request}`,
                  { state: row?.original }
                )
              }
            >
              {row?.original?.id_open_request}
            </p>
          ),
        filterFn: (row, id, value) => {
          return value.includes(row.getValue(id))
        }
      },
      {
        header: headers.PONUMBER,
        accessorKey: 'po_number',
        cell: (info) => {
          return (
            <p
              className='underline cursor-pointer text-blue-500'
              onClick={() => handlePODetailsClick('vendor', info?.getValue())}
            >
              {info?.getValue()}
            </p>
          )
        },
        filterFn: (row, id, value) => {
          return value.includes(row.getValue(id))
        }
      },
      {
        header: headers.SKU,
        accessorKey: 'sku',
        filterFn: (row, id, value) => {
          return value.includes(row.getValue(id))
        }
      },

      {
        header: headers.VENDORRESPONSE,
        accessorKey: 'supplier_response',
        filterFn: (row, id, value) => {
          let value1 = value.toLowerCase()
          let value2 = row.getValue(id)?.toLowerCase()
          return value1.includes(value2)
        }
      },
      {
        header: headers.CRSTATUS,
        accessorKey: 'status',
        filterFn: (row, id, value) => {
          return value.includes(row.getValue(id))
        }
      },
      {
        header: headers.REQUESTTYPE,
        accessorKey: 'request_type',

        filterFn: (row, id, value) => {
          const cellValue = row.getValue(id);

          // // Check if cellValue is null or undefined before calling toString()
          if (cellValue == null) {
            return false; // or any other logic you want to apply when the value is null
          }
          let words = value.split(/[\s,]+/).map(word => word.toLowerCase());  // Split by comma and spaces
          return words.includes(cellValue?.toLowerCase())
        }
      },
      {
        header: "CR AGE (DAY)",
        accessorKey: 'crAge',
        filterFn: (row, id, value) => {
          let cellValue = row.getValue(id)
          if (row.getValue(id) == null) {
            return false; // or any other logic you want to apply when the value is null
          }
          let getFromInputBox = value.split(/[\s,]+/).map(word => word);  // Split by comma and spaces
          return getFromInputBox.includes(cellValue)
        }
      },
      {
        header: headers.REQUESTEDBY,
        accessorKey: 'created_by',
        filterFn: (row, id, value) => {
          return value.includes(row.getValue(id))
        }
      },
      {
        header: headers.OPENQUANTITY,
        accessorKey: 'OPEN_QTY',
        // filterFn: (row, id, value) => {
        //   return value.includes(row.getValue(id))
        // }
      },
      
      {
        header: headers.INVENTORYANALYST,
        accessorKey: 'inventory_analyst',
        cell: (info) => info?.getValue() ?? '',
        filterFn: (row, id, value) => {
          return value.includes(row.getValue(id))
        },
        size: 80
      },
      {
        header: headers.SELECTEDSHIPWEEK,
        accessorKey: 'vendor_selected_sw',
        Filter: MultiSelectColumnFilter,
        filter: 'multiSelect',
        filterFn: (row, id, value) => {
          return value.includes(row.getValue(id))
        },
        size: 80
      },


      {
        header: headers.CHANGEDSW,
        accessorKey: 'requested_ship_week',
        // filterFn: (row, id, value) => {
        //   return value.includes(row.getValue(id))
        // }
        Filter: MultiSelectColumnFilter,
        filter: 'multiSelect',
        filterFn: (row, id, value) => {
          return value.includes(row.getValue(id))
        }
      },
      {
        header: headers.ORGSW,
        accessorKey: 'original_ship_week',
        filterFn: (row, id, value) => {
          return value.includes(row.getValue(id))
        }
      },
      {
        header: headers.PREVIOUSW,
        accessorKey: 'PREVS_CURRENTSHIPWEEK'
        // filterFn: (row, id, value) => {
        //   return value.includes(row.getValue(id))
        // }
      },
      {
        header: headers.CURRSW,
        accessorKey: 'current_ship_week',
        filterFn: (row, id, value) => {
          return value.includes(row.getValue(id))
        }
      },
      {
        header: headers.ONTIMETARGET,
        accessorKey: 'on_time_target',
        filterFn: (row, id, value) => {
          return value.includes(row.getValue(id))
        }
      },
      {
        header: headers.CREATEDATE,
        accessorKey: 'created_at',
        cell: (info) =>
          info?.getValue() !== null ? formatDateFit(info?.getValue()) : '',
        filterFn: DatefilterFn
      },
      {
        header: headers.UPDATEDATE,
        accessorKey: 'updated_at',
        cell: (info) =>
          info?.getValue() !== null ? formatDateFit(info?.getValue()) : '',
        filterFn: DatefilterFn
      }
    ],
    [headers]
  )
  const {
    state:
    {  },
    SearchBuyer, searchVendorORSKU, addFilterData } = CommonAPICall()
  const [filterData,setFilterData] = useState({})
  const [check,setcheck] = useState(false)
  const fetchAllRequests = () => {
    setLoading(true)
    console.log(filterData);
    
    axios
      .get(
        `${process.env.REACT_APP_API_DOMAIN}:8088/api/get-supplier-cr-data?vendorNumber=${user?.supplierId}`, ValidateAPI
      )
      .then((response) => {
        console.log('response from FCR: ', response?.data)
        if (response?.data?.[0]?.Message) return setRequestData([])
        setRequestData(response?.data)
        setLoading(false)
      })
      .catch((error) => {
        setLoading(false)
        if (error.response.status === 401 || error.response.status === 403) {
          navigate('/login-error')
        }
      })
  }
  function getFilterData(userid, tableName) {
    setLoading(true)
    axios
      .get(
        `${process.env.REACT_APP_API_DOMAIN}:8088/filter/preference/getFilterDetails?hftUserId=${userid}&tableName=${tableName}`,
        ValidateAPI
      )
      .then((response) => {
        setFilterData(response?.data?.data)
        setcheck(true)
      })
      .catch((error) => {
      
        if (error.response.status === 401 || error.response.status === 403) {
          setLoading(false)
          navigate('/login-error')
        } else if (error.response.status === 404) {
          setcheck(true)
        }
      })
  }
  useEffect(() => {
      getFilterData(user?.supplierId,tableId)
  }, [])
  useEffect(() => {
    if(check){
      fetchAllRequests()
    }
  }, [check])

  return (
    <div className='w-full flex flex-col gap-6 justify-start items-start p-4'>
      <div className='w-full justify-between flex items-center'>
        <div className=''>
          <p className='text-xl'>{POChangeRequests}</p>
        </div>
        {/* <UserProfile /> */}
      </div>
      <div className='w-full text-xs flex justify-start items-center gap-1 font-medium text-gray-600'>
        <p className='flex gap-1 items-center justify-center'>
          <span
            className='hover:underline cursor-pointer'
            onClick={() => navigate('/vendor')}
          >
            {Home}
          </span>
          <span>{'>'}</span>
        </p>
        <p className='flex gap-1 items-center justify-center'>
          <span>{POChangeRequests}</span>
        </p>
      </div>
      {loading ? (
        <div className='w-full min-h-[300px] flex justify-center items-center'>
          <p>{Loading1}</p>
        </div>
      ) : (
        <div className='w-full overflow-x-auto'>
          <BasicTable
            hftid={user?.supplierId}
            tableId={tableId}
            tableData={requestData}
            columns={columns} 
            filterData={filterData}
            setFilterData={setFilterData}
            />
        </div>
      )}
    </div>
  )
}

export default VendorRequest
