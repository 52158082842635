export const inititalState = {
   buyerListData: [],
   vendorListData: [],
   skuListData: [],
   getFilterDataList:{},
   skuListData: [],
  }
  export const APIReducer = (state, action) => {
        switch (action.type) {
      case 'BUYERCOUNT':
        return {
          ...state,
          buyerListData: action.payload
        }
  
      case 'VENDORCOUNT':
        return {
          ...state,
          vendorListData: action.payload
        }

      case 'SKUCOUNT':
          return {
            ...state,
            skuListData: action.payload
          }
        case 'FILTERDATA':         
        return {
          ...state,
          getFilterDataList: action.payload
        }  
        case 'SKUCOUNT':
          return {
            ...state,
            skuListData: action.payload
          }     
    }
  }
  