import React, { useEffect, useMemo, useState } from 'react'
import UserProfile from '../../components/UserProfile'
import { useNavigate, useParams } from 'react-router-dom'
import axios from 'axios'
import { ValidateAPI, checkPd, handleDownload } from '../../utils/Utils'
import DatePicker from 'react-datepicker'
import { getWeek, getWeekDifferenceStatus } from '../buyer/NewPoList'
import 'react-datepicker/dist/react-datepicker.css'
import { formatDateFit } from './BuyerRequestDetails'
import BasicTable from '../../components/BasicTable'
import { useTranslation } from 'react-i18next'
import i18next from 'i18next'
import PopupModal from '../../components/PopupModal'
import Loading from '../../components/Loading'

const PoNonEditLine = ({ rowData }) => {
  return (
    <tr className='text-center'>
      <td className='p-2 py-4 text-xs text-center'>{rowData?.['REV#']}</td>
      <td className='p-2 py-4 text-xs'>{rowData?.SKU}</td>
      <td className='p-2 py-4 text-xs text-center'>{rowData?.DESCRIPTION}</td>
      <td className='p-2 py-4 text-xs'>{rowData?.ORG_SW}</td>
      <td className='p-2 py-4 text-xs'>{rowData?.REQ_SW}</td>
      <td className='p-2 py-4 text-xs'>{rowData?.DC}</td>
      <td className='p-2 py-4 text-xs'>{rowData?.UPC}</td>
      <td className='p-2 py-4 text-xs'>{rowData?.QUANTITY}</td>
      <td className='p-2 py-4 text-xs'>${rowData?.UNIT_COST}</td>
      <td className='p-2 py-4 text-xs text-center'>
        ${rowData?.EXTENDED_COST}
      </td>
      <td className='p-2 py-4 text-xs'>{rowData?.SHIP_QTY}</td>
    </tr>
  )
}

const PoEditLine = ({ rowData, array }) => {
  const [user, setUser] = useState(JSON.parse(localStorage.getItem('userData')))
  const [editable, setEditable] = useState(false)
  const [sw, setSw] = useState(rowData?.REQ_SW)
  const [dc, setDc] = useState(rowData?.DC)
  const [reqDate, setReqDate] = useState(new Date())
  const [disableDC, setdisableDc] = useState(false)
  const [disableSW, setdisableSW] = useState(false)
  const req_obj = {
    id_po_supplier_portal: 1,
    po_number: Number(rowData?.PO_NUMBER),
    po_line_number: 1,
    revision_number: rowData?.['REV#'],
    sku: rowData?.SKU,
    vendor_number: rowData?.['VENDOR#'],
    vendor_name: rowData?.VENDOR_NAME,
    request_type: '',
    description: rowData?.DESCRIPTION,
    original_dc: rowData?.DC,
    // requested_dc: dc,
    requested_dc: '',
    reason_code_dc: '',
    original_ship_week: rowData?.ORG_SW,
    // requested_ship_week: "getWeek(reqDate)",
    requested_ship_week: '',
    reason_code_ship_week: '',
    original_qty: rowData?.QUANTITY,
    requested_qty: rowData?.QUANTITY,
    reason_code_qty: '',
    hft_user_id: user?.hftOracleId,
    supplier_contact_id: rowData?.['VENDOR#'],
    supplier_response: 'Awaiting Response',
    status: 'OPEN',
    followups: 1
  }
  const [dcList, setDCList] = useState(
    JSON.parse(sessionStorage?.getItem('dcList'))
  )
  function getWeek(date) {
    const currentDate = new Date(date)
    const currentYear = currentDate.getFullYear()
    const firstDayOfYear = new Date(currentYear, 0, 1)
    const days = Math.round(
      (currentDate - firstDayOfYear) / (24 * 60 * 60 * 1000)
    )
    const weekNumber = Math.ceil((days + firstDayOfYear.getDay() + 1) / 7)
    return `${weekNumber}.${currentYear % 100}`
  }

  const getWeekDifferenceStatus = (currentShipWeek, updatedShipWeek) => {
    const currentWeekNumber = parseFloat(currentShipWeek)
    const updatedWeekNumber = parseFloat(updatedShipWeek)
    if (!currentWeekNumber) {
      return 'Expedite'
    }
    if (currentWeekNumber > updatedWeekNumber) {
      return 'Expedite'
    } else if (currentWeekNumber < updatedWeekNumber) {
      return 'Defer'
    }
  }

  const handleSaveClick = () => {
    // const req_sw = getWeek(reqDate);
    // console.log("REQ SW: ", req_sw);
    // if(!isNaN(req_sw)){
    //   setSw((prevSw) => {
    //     console.log("Previous sw:", prevSw);
    //     return req_sw;
    //   });
    // }
    // console.log("sw: ", sw);

    let changes = {}

    // if (qty !== rowData?.QUANTITY) {
    // changes.requested_qty = qty;
    // }
    let req_type = ''
    if (dc !== rowData?.DC) {
      changes.requested_dc = dc
      req_type = 'Divert'
      console.log(dc)
    }
    if (sw !== rowData?.ORG_SW) {
      changes.requested_ship_week = getWeek(reqDate)
      req_type = getWeekDifferenceStatus(rowData?.ORG_SW, getWeek(reqDate))
    }

    if (Object.keys(changes).length === 0) {
      console.log('No changes to save.')
      setEditable(false)
      return
    }

    req_obj.request_type = req_type

    const updatedReqObj = { ...req_obj, ...changes }

    console.log('Updated Req Obj:', updatedReqObj)

    array((prevArray) => [...prevArray, updatedReqObj])

    // console.log("updated array: ", updatedArray)

    setEditable(false)
  }

  const handleUpdatedValues = () => {
    if (sw !== (rowData?.CURRENT_SW ?? rowData?.ORG_SW)) {
      const shipReqType = getWeekDifferenceStatus(
        rowData?.CURRENT_SW ?? rowData?.ORG_SW,
        sw
      )
      const req_obj = {
        id_po_supplier_portal: 1,
        po_number: Number(rowData?.PO_NUMBER),
        po_line_number: 1,
        revision_number: Number(rowData?.['REV#']),
        sku: rowData?.SKU,
        upc: rowData?.UPC,
        vendor_number: rowData?.['VENDOR#'],
        vendor_name: rowData?.VENDOR_NAME,
        request_type: shipReqType,
        description: rowData?.DESCRIPTION,
        original_dc: rowData?.DC,
        requested_dc: '',
        reason_code_dc: '',
        original_ship_week: rowData?.ORG_SW,
        requested_ship_week: rowData?.UPDATED_SW,
        reason_code_ship_week: '',
        current_ship_week: rowData?.CURRENT_SW,
        original_qty: rowData?.QUANTITY,
        requested_qty: rowData?.QUANTITY,
        reason_code_qty: '',
        hft_user_id: Number(user?.hftOracleId),
        supplier_contact_id: 123,
        supplier_response: 'Awaiting Response',
        status: 'OPEN',
        followups: 1,
        inventory_analayst: rowData?.INVENTORY_ANALYST,
        vendor_manager: rowData?.VENDOR_MANAGER,
        created_by: user?.fullName
      }
      array?.push(req_obj)
      setEditable(false)
    } else if (dc !== rowData?.DC) {
      const req_obj = {
        id_po_supplier_portal: 1,
        po_number: Number(rowData?.PO_NUMBER),
        po_line_number: 1,
        revision_number: Number(rowData?.['REV#']),
        sku: rowData?.SKU,
        upc: rowData?.UPC,
        vendor_number: rowData?.['VENDOR#'],
        vendor_name: rowData?.VENDOR_NAME,
        request_type: 'Divert',
        description: rowData?.DESCRIPTION,
        original_dc: rowData?.DC,
        requested_dc: rowData?.UPDATED_DC,
        reason_code_dc: '',
        original_ship_week: rowData?.ORG_SW,
        requested_ship_week: rowData?.REQ_SW,
        reason_code_ship_week: '',
        current_ship_week: rowData?.CURRENT_SW,
        original_qty: rowData?.QUANTITY,
        requested_qty: rowData?.QUANTITY,
        reason_code_qty: '',
        hft_user_id: Number(user?.hftOracleId),
        supplier_contact_id: 123,
        supplier_response: 'Awaiting Response',
        status: 'OPEN',
        followups: 1,
        inventory_analayst: rowData?.INVENTORY_ANALYST,
        vendor_manager: rowData?.VENDOR_MANAGER,
        created_by: user?.fullName
      }
      array?.push(req_obj)
      setEditable(false)
    } else {
      alert(i18next.t('PopUpMessage.NochangesnotedPleasetryagain'))
      setEditable(false)
    }
  }

  const hanldeCancelByLine = () => {
    setSw(rowData?.ORG_SW)
    setDc(rowData?.DC)
    setEditable(false)
    setdisableDc(false)
    setdisableSW(false)
  }

  const handleDate = (value) => {
    setReqDate(value)
    setSw(getWeek(value))
  }
  return (
    <tr className='text-center'>
      <td className='p-2 py-4 text-xs'>{rowData?.['REV#']}</td>
      <td className='p-2 py-4 text-xs'>{rowData?.SKU}</td>
      <td className='p-2 py-4 text-xs'>{rowData?.DESCRIPTION}</td>
      <td className='p-2 py-4 text-xs'>{rowData?.ORG_SW}</td>
      <td className='p-2 py-4 text-xs'>
        <div className='flex justify-center w-full items-center'>
          {editable ? (
            <DatePicker
              showWeekNumbers
              filterDate={(date) => date.getDay() === 1}
              selected={reqDate}
              onChange={(date) => {
                handleDate(date)
                setdisableDc(true)
              }}
              className='p-1 border border-gray-300 rounded-md'
              calendarStartDay={1}
              showYearDropdown
              disabled={disableSW}
              minDate={new Date()}
            />
          ) : sw !== rowData?.ORG_SW ? (
            <p className='relative'>
              {sw}
              <span className='bg-green-500 p-1 rounded-full absolute top-1'></span>
            </p>
          ) : (
            <p>
              <svg
                className='w-4 h-4 text-gray-900'
                aria-hidden='true'
                xmlns='http://www.w3.org/2000/svg'
                fill='currentColor'
                viewBox='0 0 20 18'
              >
                <path d='M12.687 14.408a3.01 3.01 0 0 1-1.533.821l-3.566.713a3 3 0 0 1-3.53-3.53l.713-3.566a3.01 3.01 0 0 1 .821-1.533L10.905 2H2.167A2.169 2.169 0 0 0 0 4.167v11.666A2.169 2.169 0 0 0 2.167 18h11.666A2.169 2.169 0 0 0 16 15.833V11.1l-3.313 3.308Zm5.53-9.065.546-.546a2.518 2.518 0 0 0 0-3.56 2.576 2.576 0 0 0-3.559 0l-.547.547 3.56 3.56Z' />
                <path d='M13.243 3.2 7.359 9.081a.5.5 0 0 0-.136.256L6.51 12.9a.5.5 0 0 0 .59.59l3.566-.713a.5.5 0 0 0 .255-.136L16.8 6.757 13.243 3.2Z' />
              </svg>
            </p>
          )}
        </div>
      </td>
      <td className='p-2 py-4 text-xs'>{rowData?.QUANTITY}</td>
      <td className='p-2 py-4 text-xs'>{rowData?.DC}</td>
      <td className='p-2 py-4 text-xs'>
        <div className='flex justify-center w-full items-center'>
          {editable ? (
            <select
              disabled={disableDC}
              className='p-1 rounded-md border border-gray-300 outline-none'
              onChange={(e) => {
                setDc(e?.target?.value)
                setdisableSW(true)
              }}
              value={dc}
            >
              <option selected>Select</option>
              {dcList?.map((dcvalue) => {
                return (
                  <option value={dcvalue?.DC_NAME}>{dcvalue?.DC_NAME}</option>
                )
              })}
            </select>
          ) : dc !== rowData?.DC ? (
            <p>{dc}</p>
          ) : (
            <p>
              <svg
                className='w-4 h-4 text-gray-800'
                aria-hidden='true'
                xmlns='http://www.w3.org/2000/svg'
                fill='currentColor'
                viewBox='0 0 20 18'
              >
                <path d='M12.687 14.408a3.01 3.01 0 0 1-1.533.821l-3.566.713a3 3 0 0 1-3.53-3.53l.713-3.566a3.01 3.01 0 0 1 .821-1.533L10.905 2H2.167A2.169 2.169 0 0 0 0 4.167v11.666A2.169 2.169 0 0 0 2.167 18h11.666A2.169 2.169 0 0 0 16 15.833V11.1l-3.313 3.308Zm5.53-9.065.546-.546a2.518 2.518 0 0 0 0-3.56 2.576 2.576 0 0 0-3.559 0l-.547.547 3.56 3.56Z' />
                <path d='M13.243 3.2 7.359 9.081a.5.5 0 0 0-.136.256L6.51 12.9a.5.5 0 0 0 .59.59l3.566-.713a.5.5 0 0 0 .255-.136L16.8 6.757 13.243 3.2Z' />
              </svg>
            </p>
          )}
        </div>
      </td>
     { 
     user?.userGroupName !== "OVERSEAS_ROLE" &&
     <td className='p-2 py-4 text-xs'>
        {editable ? (
          <div className='flex gap-2 justify-center items-start'>
            <button
              className='text-xs hover:underline'
              onClick={handleSaveClick}
            >
              Save
            </button>
            <button
              className='text-xs hover:underline'
              onClick={hanldeCancelByLine}
            >
              Cancel
            </button>
          </div>
        ) : (
          !(rowData?.HFT_REQUEST || checkPd(rowData?.CURRENT_SW )) && (
            <button
              className='text-xs hover:underline'
              onClick={() => setEditable(true)}
            >
              Edit
            </button>
          )
        )}
      </td>}
    </tr>
  )
}

const PoDetailsUpdated = () => {
  const [limiter, setLimiter] = useState(10)
  const [searchWord, setSearchWord] = useState('')
  const [poData, setPoData] = useState([])
  const [showCancelModal, setShowCancelModal] = useState(false)
  const [showCreateRequestModal, setShowCreateRequestModal] = useState(false)
  const [loading, setLoading] = useState(true)
  const { id } = useParams()
  const poNumber = id?.toString()
  const [reqArry, setReqArry] = useState([])
  const navigate = useNavigate()
  const user = JSON.parse(localStorage.getItem('userData'))
  const { t } = useTranslation()
  const [columnVisibility, setColumnVisibility] = useState({
    UNIT_COST: user?.userGroupName === 'OVERSEAS_ROLE' ? false : true,
    EXTENDED_COST: user?.userGroupName === 'OVERSEAS_ROLE' ? false : true
  })
  const {
    Home,
    POList,
    PODetails,
    Cancel,
    PO,
    Vendor,
    VendorName,
    InventoryAnalyst,
    POHeaderDesc,
    VendorManager,
    DownloadPDF,
    ShowEntries,
    Rev,
    SKU,
    Description,
    ORGSW,
    QTY,
    POHeaderDetails,
    CreateChangeRequest,
    POLineDetails,
    POLineChangeRequest,
    ChangeSW,
    ORGDC,
    ChangeDC,
    Search
  } = t('POHeaderDetails')

  const headers = {
    PONUMBER: t('POList.PONUMBER'),
    SKU: t('POList.SKU'),
    DESCRIPTION: t('POList.DESCRIPTION'),
    UPC: t('POList.UPC'),
    MESSAGE: t('POList.MESSAGES'),
    REVNO: t('POList.REVISIONNUMBER'),
    REASONCODE: t('POList.REASONCODE'),
    DC: t('POList.DC'),
    REQSW: t('POList.REQSW'),
    ORGSW: t('POList.ORGSW'),
    CURRSW: t('POList.CURRENTSW'),
    ONTIMETARGET: t('POList.ONTIMETARGET'),
    DELTA: t('POList.DELTA'),
    UNITCOST: t('POList.UNITCOST'),
    EXTENDEDCOST: t('POList.EXTENDEDCOST'),
    LSTUPDATED: t('POList.DATELASTUPDATED'),
    VENDOR: t('POList.VENDOR'),
    VENDORNAME: t('POList.VENDORNAME'),
    VENDORMANAGER: t('POList.VENDORMANAGER'),
    SHIPOFFICE: t('POList.SHIPPINGOFFICE'),
    SHIPqty: t('POList.SHIPPEDQUANTITY'),
    INVENTORYANALYST: t('POList.INVENTORYANALYST'),
    HEADERDESC: t('POList.POHEADERDESCRIPTION')
  }

  const NonEditPoColumns = useMemo(
    () => [
      {
        header: headers.REVNO,
        accessorKey: 'REV#'
      },
      {
        header: headers.PONUMBER,
        accessorKey: 'PO_NUMBER'
      },
      {
        header: headers.SKU,
        accessorKey: 'SKU'
      },
      {
        header: headers.DESCRIPTION,
        accessorKey: 'DESCRIPTION'
      },
      {
        header: 'REQ SW',
        accessorKey: 'REQ_SW'
      },
      {
        header: headers.ORGSW,
        accessorKey: 'ORG_SW'
      },
      {
        header: headers.CURRSW,
        accessorKey: 'CURRENT_SW'
      },
      {
        header: headers.DC,
        accessorKey: 'DC'
      },
      {
        header: headers.UPC,
        accessorKey: 'UPC'
      },
      {
        header: headers.UNITCOST,
        accessorKey: 'UNIT_COST',
        enableHiding:false,
        cell: ({getValue}) => `$ ${getValue()}`
      },
      {
        header: headers.EXTENDEDCOST,
        accessorKey: 'EXTENDED_COST',
        enableHiding:user?.userGroupName === 'OVERSEAS_ROLE',
        cell: ({getValue}) => `$ ${getValue()}`
      },
      {
        header: headers.SHIPOFFICE,
        accessorKey: 'SHIPPING_OFFICE'
      },
      {
        header: headers.SHIPqty,
        accessorKey: 'SHIP_QTY'
      },
      {
        header: 'PO QUANTITY',
        accessorKey: 'QUANTITY'
        // filterFn: (row, id, value) => {
        //   return value.includes(row.getValue(id))
        // }
      },
      {
        header: headers.DELTA,
        accessorKey: 'DELTA'
      },
      {
        header: headers.ONTIMETARGET,
        accessorKey: 'ONTARGET_SHIP_WK'
      },
      {
        header: headers.REASONCODE,
        accessorKey: 'REACON_CODE'
      },
      {
        header: headers.MESSAGE,
        accessorKey: 'MESSAGE'
      },
      {
        header: headers.VENDOR,
        accessorKey: 'VENDOR#'
      },
      {
        header: headers.VENDORNAME,
        accessorKey: 'VENDOR_NAME'
      },
      {
        header: headers.VENDORMANAGER,
        accessorKey: 'VENDOR_MANAGER'
      },
      {
        header: headers.INVENTORYANALYST,
        accessorKey: 'INVENTORY_ANALYST'
      },
      {
        header: headers.HEADERDESC,
        accessorKey: 'PO_HEADER_DESC'
      },
            {
        header: headers.LSTUPDATED,
        accessorKey: 'DATE_LAST_UPDATED',
        cell: (info) =>
          info?.getValue() !== null ? formatDateFit(info?.getValue()) : ''
      }
    ],
    [headers]
  )
  const getOverseasRoleType = () => {
    if (user.userGroupRole === '5') return user?.userGroupName
    else return ''
  }
  const fetchPoDetails = () => {
    axios
      .get(
        `${process.env.REACT_APP_API_DOMAIN}:8088/api/oracle-data-by-po?poNumber=${poNumber}&role=${getOverseasRoleType()}`, ValidateAPI
      )
      .then((response) => {
        console.log('Fetch Po response: ', response?.data)
        setLoading(false)
        setPoData(response?.data)

      })
      .catch((error) => {
        if (error.response.status === 401 || error.response.status === 403) {
          navigate('/login-error')
        }
        setLoading(false)
      })
  }

  const closeCreateRequestModal = () => {
    setShowCreateRequestModal(false)
  }

  const openCreateRequestModal = () => {
    setShowCreateRequestModal(true)
  }

  const handleCreateRequest = () => {
    if (reqArry?.length !== 0) {
      console.log('will run create request logic')
      console.log('Array payload: ', reqArry)
      axios
        .post(
          `${process.env.REACT_APP_API_DOMAIN}:8088/api/create-request`,
          reqArry, ValidateAPI
        )
        .then((response) => {
          console.log('response from CR:', response?.data)
          closeCreateRequestModal()
          i18next.t('PopUpMessage.Doyouwanttoconfirmtheseorders')
          setReqArry([])
          fetchPoDetails()
          window.close()
        })
        .catch((error) => {
          if (error.response.status === 401 || error.response.status === 403) {
            navigate('/login-error')
          }
        })
    } else {
      alert(i18next.t('PopUpMessage.Therearenochangesmadetosubmit'))
    }
  }

  useEffect(() => {
    fetchPoDetails()
    window.scroll(0, 0)
  }, [])

  useEffect(() => {
    console.log('updated req array: ', reqArry)
  }, [reqArry])

  const closeCancelModal = () => {
    setShowCancelModal(false)
  }

  const openCancelModal = () => {
    setShowCancelModal(true)
  }

  const handleCancel = () => {
    window.location.reload(false)
    setReqArry([])
    closeCancelModal()
  }
  return (
    <div className='w-full flex flex-col gap-6 justify-start items-start p-4'>
      {/*Header CTA Buttons*/}
      <div className='w-full text-xs flex justify-start items-center gap-1 font-medium text-gray-600'>
        <p className='flex gap-1 items-center justify-center'>
          <span
            className='hover:underline cursor-pointer'
            onClick={() => navigate(user?.userGroupRole === "2" ? '/vendor' : 'buyer')}
          >
            {Home}
          </span>
          <span>{'>'}</span>
        </p>
        <p className='flex gap-1 items-center justify-center'>
          <span
            className='hover:underline cursor-pointer'
            onClick={() => navigate(user?.userGroupRole === "2" ? '/vendor/open_pos' : '/buyer/po_list')}
          >
            {POList}
          </span>
          <span>{'>'}</span>
        </p>
        <p className='flex gap-1 items-center justify-center'>
          <span>
            {PODetails} - {id}
          </span>
        </p>
      </div>
      {/* Progress icon while loading data and if data is not present shows message */}
      {
        loading ?
          <Loading /> :
          (
            poData?.length >0 ?
              <div className='w-full'>
                <div className='w-full flex justify-between items-center h-[40px]'>
                  <div>
                    <p className='font-semibold text-xl'>{POHeaderDetails}</p>
                  </div>
                  <div className='flex gap-4'>
                    <button
                      className='bg-[rgb(68,114,196)] text-white h-[37px] px-3 rounded-md text-sm'
                      onClick={() => window.close()}>Close</button>
                    {reqArry?.length > 0 && (
                      <div className='flex justify-center items-center gap-4 '>
                        <button
                          className='bg-[rgb(68,114,196)] text-white h-[37px] px-3 rounded-md text-sm'
                          onClick={openCreateRequestModal}
                        >
                          {CreateChangeRequest}
                        </button>
                        <PopupModal
                          isOpen={showCreateRequestModal}
                          message={i18next.t('PopUpMessage.Doyouwanttocreateachangerequest')}
                          onCancel={closeCreateRequestModal}
                          cancelText={i18next.t('AcceptModal.Cancel')}
                          onConfirm={handleCreateRequest}
                          confirmText={i18next.t('AcceptModal.Confirm')}
                        />
                        <button
                          className='bg-[#4472C4] text-white h-[37px] px-3 rounded-md text-sm min-w-[111px]'
                          onClick={openCancelModal}
                        >
                          {Cancel}
                        </button>
                        <PopupModal
                          isOpen={showCancelModal}
                          message={i18next.t('PopUpMessage.DoyouwanttoCancelChanges')}
                          onCancel={closeCancelModal}
                          cancelText={i18next.t('AcceptModal.Cancel')}
                          onConfirm={handleCancel}
                          confirmText={i18next.t('AcceptModal.Confirm')}
                        />
                      </div>
                    )}
                  </div>
                </div>
                {/*PO Header Details */}
                <div className='border border-[#E6EDFF] w-full rounded-md px-4 py-2'>
                  <div className='border-b border-[#E6EDFF] w-full justify-between items-start flex py-2'>
                    <div className='w-[15%] flex flex-col items-center justify-start gap-2'>
                      <p className='text-sm font-semibold'>PO Number</p>
                      <p className='text-xs text-center'>{poData?.[0]?.PO_NUMBER}</p>
                    </div>
                    {/* <div className='w-[15%] flex flex-col items-center justify-start gap-2'>
                      <p className='text-sm font-semibold'>{Vendor}</p>
                      <p className='text-xs text-center'>{poData?.[0]?.['VENDOR#']}</p>
                    </div> */}
                    <div className='w-[35%] flex flex-col items-center justify-start gap-2'>
                      <p className='text-sm font-semibold'>{VendorName}</p>
                      <p className='text-xs text-center'>{poData?.[0]?.VENDOR_NAME}</p>
                    </div>
                    {/* <div className="w-[25%] flex flex-col items-center justify-start gap-2">
                   <p className="text-sm font-semibold">Order Status #</p>
                   <p className="text-xs text-center">{""}</p>
                 </div> */}
                    <div className='w-[20%] flex flex-col items-center justify-start gap-2'>
                      <p className='text-sm font-semibold'>{InventoryAnalyst}</p>
                      <p className='text-xs text-center capitalize'>
                        {poData?.[0]?.INVENTORY_ANALYST?.toLowerCase()}
                      </p>
                    </div>
                  </div>
                  <div className=' w-full justify-between items-start flex py-2'>
                    <div className='w-[15%] flex flex-col items-center justify-start gap-2'>
                      <p className='text-sm font-semibold text-center'>{POHeaderDesc}</p>
                      <p className='text-xs text-center'>{poData?.[0]?.PO_HEADER_DESC}</p>
                    </div>
                    <div className='w-[25%] flex flex-col items-center justify-start gap-2'>
                      <p className='text-sm font-semibold'>{VendorManager}</p>
                      <p className='text-xs text-center capitalize'>
                        {poData?.[0]?.VENDOR_MANAGER?.toLowerCase()}
                      </p>
                    </div>
                    {
                      user?.userGroupName !== "OVERSEAS_ROLE" &&
                      <div className='w-[20%] flex flex-col items-center justify-start gap-2'>
                        <p className='text-sm font-semibold'>{DownloadPDF}</p>
                        <button
                          className='text-xs text-center flex justify-center'
                          onClick={() => handleDownload(poData?.[0]?.PO_NUMBER)}
                        >
                          <svg
                            className='w-4 h-4  cursor-pointer'
                            aria-hidden='true'
                            xmlns='http://www.w3.org/2000/svg'
                            fill='none'
                            viewBox='0 0 16 18'
                          >
                            <path
                              stroke='currentColor'
                              stroke-linecap='round'
                              stroke-linejoin='round'
                              stroke-width='1.5'
                              d='M8 1v11m0 0 4-4m-4 4L4 8m11 4v3a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2v-3'
                            />
                          </svg>
                        </button>
                      </div>
                    }
                  </div>
                </div>
                {/*PO Line Details */}
                <div className='w-full flex justify-start items-start mt-1 flex-col gap-4'>
                  <div>
                    <p className='font-semibold text-xl'>{POLineDetails}</p>
                  </div>
                  <BasicTable columns={NonEditPoColumns} tableData={poData} 
                  columnVisibility={columnVisibility}
                  setColumnVisibility={setColumnVisibility}/>
                </div>
                  {/* removed this line not to show edit option */}
                {/*PO Line Level Edit */}
                {/* {!window.location.pathname.includes('/vendor') && (
                  <div className='w-full flex justify-start items-start mt-1 flex-col gap-4'>
                    <div>
                      <p className='font-semibold text-xl'>{POLineChangeRequest}</p>
                    </div>
                    <div className='w-full justify-between flex items-center'>
                      <div className='flex justify-center items-center gap-4'>
                        <p className='text-sm text-[#8B8B8B] font-normal'>
                          {ShowEntries}
                        </p>
                        <select
                          name='splice_limit'
                          id='splice_limit'
                          className='text-xs px-2 py-1 border border-gray-200 rounded-md outline-none'
                          onChange={(e) => setLimiter(e?.target?.value)}
                        >
                          <option value={10}>10</option>
                          <option value={25}>25</option>
                          <option value={50}>50</option>
                          <option value={100}>100</option>
                        </select>
                      </div>
                      <div className='border rounded-md border-gray-300 p-2 flex gap-3 items-center h-[30px]'>
                        <input
                          type='text'
                          name=''
                          id=''
                          placeholder={Search}
                          className='w-[85%] outline-none text-xs'
                          value={searchWord}
                          onChange={(e) => setSearchWord(e?.target?.value)}
                        />
                        <button>
                          <svg
                            className='w-3 h-3 text-gray-800 cursor-pointer'
                            aria-hidden='true'
                            xmlns='http://www.w3.org/2000/svg'
                            fill='none'
                            viewBox='0 0 20 20'
                          >
                            <path
                              stroke='currentColor'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                              strokeWidth='2'
                              d='m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z'
                            />
                          </svg>
                        </button>
                      </div>
                    </div>
                    <div className='w-full p-3 border border-[#E6EDFF]'>
                      <table className='w-full'>
                        <thead>
                          <tr className='border-[#E6EDFF] border-b'>
                            <th className='p-2 py-4 font-medium text-xs'>{Rev}</th>
                            <th className='p-2 py-4 font-medium text-xs'>{SKU}</th>
                            <th className='p-2 py-4 font-medium text-xs'>
                              {Description}
                            </th>
                            <th className='p-2 py-4 font-medium text-xs'>{ORGSW}</th>
                            <th className='p-2 py-4 font-medium text-xs'>{ChangeSW}</th>
                            <th className='p-2 py-4 font-medium text-xs'>{QTY}</th>
                            <th className='p-2 py-4 font-medium text-xs'>{ORGDC}</th>
                            <th className='p-2 py-4 font-medium text-xs'>{ChangeDC}</th>
                            <th className='p-2 py-4 font-medium text-xs'></th>
                          </tr>
                        </thead>
                        <tbody>
                          {poData?.map((data, index) => (
                            <PoEditLine rowData={data} key={index} array={setReqArry} />
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                )} */}
              </div>
              :
              <div className='w-full h-14 flex flex-col justify-center items-center mt-20 gap-3 '>
                <h1 className='text-gray-500 text-lg'>Data is Not available Please Check in Change Request</h1>
                <button
                  className='bg-[rgb(68,114,196)] text-white h-[37px] p-2 rounded-md text-sm'
                  onClick={() => window.close()}>
                  Close
                </button>
              </div>
          )
      }

    </div>
  )
}

export default PoDetailsUpdated
