import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import { useMediaQuery } from 'react-responsive';
import { useTranslation } from 'react-i18next';

import {
  Bar,
  BarChart,
  CartesianGrid,
  Rectangle,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from 'recharts'
import { CommonAPICall } from '../CommonAPiContext/context';

const CommonBarChart = ({ chartData, color, type, value, checkDataType, vendorName, buyerName = '' }) => {
  const navigate = useNavigate()
  const [user, setUser] = useState(JSON.parse(localStorage.getItem('userData')))
  const [tickFontSize, setTickFontSize] = useState('12px')
  const [interval, setInterval] = useState(0)
  const { t } = useTranslation()
  const { Norelateddatafound } = t("bigbarChart")
  const { addFilterData } = CommonAPICall()
  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth
      if (width < 500) {
        setTickFontSize('8px')
        setInterval(1) // Show fewer ticks
      } else if (width < 768) {
        setTickFontSize('10px')
        setInterval(0) // Show all ticks
      } else {
        setTickFontSize('12px')
        setInterval(0) // Show all ticks
      }
    }

    window.addEventListener('resize', handleResize)
    handleResize()

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const handleBarClick = (entry) => {
    if (user?.userGroupName === "OVERSEAS_ROLE")
      return
    if (checkDataType === "Shipweek")
      return
    const requestType = entry?.activePayload?.[0]?.payload?.requestType
    if (window?.location?.pathname.includes('vendor')) {
      addFilterData({
        hftUserId: user?.supplierId,
        tableName: 'VendorChangeReqTable',
        clFilter: [
          {
            column: 'request_type',
            values: [requestType]
          }
        ]
      })
      navigate(`/vendor/po_change_requests`, {
        state: []
      })
    } else if (window?.location?.pathname.includes('buyer')) {
      let state = [];
      // if (vendorName.length === 0 && buyerName.length === 0) {
      //   state = {
      //     state: [
      //       {
      //         id: 'request_type',
      //         value: requestType
      //       },
      //       {
      //         id: 'created_by',
      //         value: user?.roleType === "MANAGER" ? '' : user?.fullName
      //       },
      //       {
      //         id: 'vendor_name',
      //         value: ''
      //       }
      //     ]
      //   }
      // } else {
      //   state = {
      //     state: [
      //       {
      //         id: 'request_type',
      //         value: requestType
      //       },
      //       {
      //         id: 'created_by',
      //         value: buyerName
      //       },
      //       {
      //         id: 'vendor_name',
      //         value: vendorName
      //       }
      //     ]
      //   }
      // }
      addFilterData({
        hftUserId: user?.hftOracleId,
        tableName: 'BuyerReqTable',
        buyerName: buyerName,
        vendorName: vendorName,
        clFilter: [
          {
            column: 'request_type',
            values: [requestType]
          }
        ]
      })
      navigate(`/buyer/hft_buyer_change_requests`, state)
    } else {
      console.log('condition failed')
    }
  }

  const isLargeScreen = useMediaQuery({ query: '(min-width: 657px)' });
  const screenWidthPercentage = (window.innerWidth / window.screen.width) * 100;

  return (
    <div className='w-full flex'>
      {/* Log the chart data to the console */}
      {/* {console.log('Chart Data:', chartData)} */}

      {/* Check if all counts are zero */}
      {chartData && chartData.length > 0 && chartData.every(item => item.Count === 0) ? (
        // <p>No Related Data Found</p> 
        <div className="flex items-center justify-center w-full h-180">
          <p className="text-m font ">{Norelateddatafound}</p>
        </div>
      ) : (
        <ResponsiveContainer width='100%' height={180}>
          <BarChart
            data={chartData}
            margin={{
              top: 5,
              right: 5,
              left: 5,
              bottom: 5
            }}
            onClick={(e) => handleBarClick(e)}
          >
            <CartesianGrid strokeDasharray='5 5' />
            <XAxis
              dataKey={type}
              className='text-xs gap-1 font-medium'
              tick={{ fontSize: isLargeScreen ? '10px' : '11px' }}
              interval={interval}
              angle={isLargeScreen ? '-10' : '0'}
              textAnchor='middle'
              tickOffset={isLargeScreen ? 50 : 0} // Add this line
            />
            <YAxis className='text-xs font-medium' />
            <Tooltip
              labelStyle={{ display: 'none' }}
              contentStyle={{
                background: 'white',
                borderRadius: '8px',
                fontSize: '12px'
              }}
            />
            <Bar
              dataKey={value}
              fill={color}
              activeBar={<Rectangle fill='gray' stroke={color} />}
            />
          </BarChart>
        </ResponsiveContainer>
      )}
    </div>
  );

}

export default CommonBarChart
