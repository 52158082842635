import React, { useEffect, useState, useRef } from 'react'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import {
  ValidateAPI,
  changeDateFormat,
  getDateofMonday,
  getPossibleShipWeek,
  getStartDate,
  reasonCodesEN,
  reasonCodesMN,
  updateActionHistory,
  validateShipWeekInput
} from '../../utils/Utils'
import { getWeek } from '../../screens/buyer/NewPoList'
import axios from 'axios'
import { weeksInBetween } from '../../screens/vendor/ConfirmPo'
import Loading from '../Loading'
import { useTranslation } from 'react-i18next'
import i18next from 'i18next'
import PopupModal from '../PopupModal'
import PopupBanner from '../PopupBanner'
import ReasonCodeList, { ReasonCodeView } from '../ReasonCodeList'

const ConfirmActionComp = ({
  requestData,
  setShowChangeRequestBanner,
  setMessageForBanner
}) => {
  const [showAcceptModal, setShowAcceptModal] = useState(false)
  const [showRequestChangeModal, setShowRequestChangeModal] = useState(false)
  const [showAcceptDatePicker, setShowAcceptDatePicker] = useState(false)
  const [reasonCodeValue, setreasonCodeValue] = useState('')
  const [secondReason, setSecondReason] = useState('')
  const [requestSW, setRequestSW] = useState('')
  const [requestDC, setRequestDC] = useState('')
  const [comment, setComment] = useState('')
  // const dc_list = ["CAM", "DAL", "DIL", "MOR", "CHI"];
  const [dc_list, setDCList] = useState(
    JSON.parse(sessionStorage?.getItem('dcList'))
  )
  const [date, setDate] = useState(new Date())
  const selectedRowArray = [requestData]
  const user = JSON.parse(localStorage.getItem('userData'))
  const [submitting, setSubmitting] = useState(false)
  const [showConfirmModal, setShowConfirmModal] = useState(false)
  // const [showChangeRequestBanner, setShowChangeRequestBanner] = useState(false)
  const [showConfirmOrdersBanner, setshowConfirmOrdersBanner] = useState(false)
  const [editCrIdResponse, setEditCrIdResponse] = useState('')
  const [editPoRow, setEditPORow] = useState('')
  const [poRow, setPoRow] = useState('')
  const [crIdResponse, setCrIdResponse] = useState('')
  const [reloadPage, setReloadPage] = useState(false)
  const [inputsw, setinputsw] = useState('')
  const [validSw, setValidSw] = useState(false)
  const { t } = useTranslation()
  const datePickerRef = useRef(null)
  const [validationMessage, setValidationMessage] = useState('')
  const {
    SelectDC,
    SelectedSW,
    REQSW,
    Comment,
    PleaseselectReasoncodefortheselectedorders,
    Changetype,
    Selectatype,
    Shipweek,
    DC,
    Price,
    Quantity,
    ReasonCode,
    Selectareason,
    RequestChange,
    Cancel,
    Submitting_load
  } = t('ConfirmActionComp')

  const closeConfirmModal = () => {
    setShowConfirmModal(false)
  }

  const openConfirmModal = () => {
    setShowConfirmModal(true)
  }

  const handleConfirmPos = () => {
    const request_payload = []
    selectedRowArray?.map((row) => {
      const payload = {
        id_open_request: row?.id_open_request,
        vendorNumber: Number(row?.vendor_number),
        vendorName: row?.vendor_name,
        createdBy: row?.inventory_analyst,
        current_ship_week: row?.requested_ship_week,
        original_ship_week: row?.requested_ship_week,
        hft_user_id: 0,
        supplier_contact_id: user?.vendorContactOrcaleId
      }
      request_payload?.push(payload)
    })
    console.log('Confirm Po payload: ', request_payload)
    setSubmitting(true)
    setShowRequestChangeModal(true)

    axios
      .post(
        `${process.env.REACT_APP_API_DOMAIN}:8088/api/supplier-confirm-po`,
        request_payload,
        ValidateAPI
      )
      .then((response) => {
        console.log('response from confirm po: ', response?.data)
        updateActionHistory('Accepted', [requestData])
        setSubmitting(false)
        setShowRequestChangeModal(false)

        if (response?.data) {
          const crIdResponse = response.data.map((item) => item.id_open_request)
          const poRow = response.data.map((item) => item.po_number)

          console.log('crIdResponse:', crIdResponse)
          console.log('poNumber:', poRow)

          setCrIdResponse(crIdResponse)
          setPoRow(poRow)
        }

        setShowChangeRequestBanner(true)
        setMessageForBanner(' Change Request Submitted Successfully ')

        closeConfirmModal()
      })
      .catch((error) => {
        console.log('error while confirming PO: ', error)
        setSubmitting(false)
      })
  }

  const rejectPop = () => {
    const rejectPosubmit = window.confirm(
      i18next.t('VendorPopUp.confirmChangesRequest')
    )
    if (rejectPosubmit) {
      setShowRequestChangeModal(true)
    }
  }

  const editConfirm = () => {
    const req_payload = []
    if (reasonCodeValue?.length > 0) {
      if (requestSW?.length > 0 || requestDC?.length > 0) {
        selectedRowArray?.map((item) => {
          const req_obj = {
            hft_cr_id: item?.id_open_request,
            requested_dc: requestDC,
            reason_code_dc: secondReason === 'dc' ? reasonCodeValue : '',
            requested_ship_week: item?.requested_ship_week ?? '',
            reason_code_ship_week: secondReason === 'sw' ? reasonCodeValue : '',
            supplier_response: 'ACCEPTED',
            current_ship_week: requestSW
              ? requestSW
              : item?.requested_ship_week,
            status: 'OPEN',
            followups: 1,
            vendor_selected_ship_week: requestSW,
            original_ship_week: requestSW
              ? requestSW
              : item?.requested_ship_week,
            ship_week_delay: Math.abs(
              weeksInBetween(requestSW ?? '', item?.requested_ship_week)
            ),
            vendorName: item?.vendor_name,
            createdBy: item?.inventory_analyst,
            requestType: 'Confirm',
            hft_user_id: 0,
            supplier_contact_id: user?.vendorContactOrcaleId
          }
          return req_payload?.push(req_obj)
        })
        console.log('payload before edit confirm: ', req_payload)
        setSubmitting(true)
        axios
          .put(
            `${process.env.REACT_APP_API_DOMAIN}:8088/api/supplier-edit-confirm-po`,
            req_payload,
            ValidateAPI
          )
          .then((response) => {
            console.log('response from editconfirm: ', response?.data)
            setRequestDC('')
            setRequestSW('')
            setSubmitting(false)
            setShowRequestChangeModal(false)
            setSecondReason('')
            setreasonCodeValue('')
            if (response?.data) {
              const crIdResponse = response.data.map(
                (item) => item.id_open_request
              )
              const poRow = response.data.map((item) => item.po_number)

              console.log('crIdResponse:', crIdResponse)
              console.log('poNumber:', poRow)

              setCrIdResponse(crIdResponse)
              setPoRow(poRow)
            }

            setShowChangeRequestBanner(true)
            setMessageForBanner(' Change Request Changed  Successfully ')
            updateActionHistory('Accepted', [requestData])

            // window.location.reload()
          })
          .catch((error) => {
            console.log('error while submitting edit confirm: ', error)
            setSubmitting(false)
          })
      } else {
        // alert("Please add new value before submitting.");
        alert(i18next.t('VendorPopUp.Pleaseaddnewvaluebeforesubmitting'))
      }
    } else {
      alert(
        i18next.t(
          'VendorPopUp.PleaseselectReasonCodeChangeTypebeforesubmitting'
        )
      )
    }
  }

  const handleRejectPos = () => {
    const request_payload = []
    const comments_payload = []
    if (!reasonCodeValue?.length > 0)
      return alert(i18next.t('VendorPopUp.Pleaseselectareasoncode'))
    if (!comment)
      return alert(i18next.t('VendorPopUp.Pleaseaddcommentbeforesubmitting'))
    selectedRowArray?.map((row) => {
      const payload = {
        id_open_request: row?.id_open_request,
        vendorNumber: Number(row?.vendor_number),
        reasonCodeShipWeek: reasonCodeValue,
        vendorName: row?.vendor_name,
        createdBy: row?.inventory_analyst,
        original_ship_week: '',
        vendorComment: comment
      }
      const comment_indi = {
        hftcrid: row?.id_open_request,
        po_number: row?.po_number,
        added_by_role_name: user?.roleName?.split('_')?.[0],
        added_by_name: user?.supplierName,
        comment: comment,
        type: 'Comment',
        SKU: row?.sku
      }
      request_payload?.push(payload)
      comments_payload?.push(comment_indi)
    })

    console.log('Reject Po payload: ', request_payload)
    console.log('Reject comments payload: ', comments_payload)
    setSubmitting(true)
    setShowRequestChangeModal(true)
    axios
      .post(
        `${process.env.REACT_APP_API_DOMAIN}:8088/api/supplier-confirm-reject-po`,
        request_payload,
        ValidateAPI
      )
      .then((response) => {
        console.log('response from reject po: ', response?.data)
        if (response?.data) {
          const crIdResponse = response.data.map((item) => item.id_open_request)
          const poRow = response.data.map((item) => item.po_number)

          console.log('crIdResponse:', crIdResponse)
          console.log('poNumber:', poRow)

          setCrIdResponse(crIdResponse)
          setPoRow(poRow)
          setShowChangeRequestBanner(true)
          setMessageForBanner(' Change Request Rejected Successfully ')
        }

        setShowRequestChangeModal(false)
        setreasonCodeValue('')
        setComment('')
        // updateActionHistory('Vendor Responded', [requestData])
        updateActionHistory('Rejected', [requestData])
        console.log('response from submitting comment: ', response?.data)

        setSubmitting(false)

            setShowChangeRequestBanner(true)
            setMessageForBanner(' Change Request Rejected Successfully ')
          })
          .catch((error) => {
         
      })
      .catch((error) => {
        console.log('error while reject PO: ', error)
      })
  }

  const renderBlock = (reason) => {
    if (reason === 'dc') {
      return (
        <div className='flex flex-col space-y-2 text-left'>
          <label htmlFor='dc delect' className='text-xs font-medium'>
            Please Select DC
          </label>
          <select
            className='w-full border border-gray-300 p-2 rounded-md text-xs'
            onChange={(e) => setRequestDC(e?.target?.value)}
          >
            <option value='' selected>
              {SelectDC}
            </option>
            {dc_list?.map((data, index) => (
              <option value={data?.DC_NAME} key={data + index}>
                {data?.DC_NAME}
              </option>
            ))}
          </select>
          <div className='flex items-center p-2'>
            <p className='text-xs'>REQ DC : </p>
            <p className='text-xs ml-2'>
              <span className='font-medium p-1 px-2 border border-gray-300 rounded-md text-xs'>
                {requestData && requestData.original_dc}
              </span>
            </p>
          </div>
        </div>
      )
    } else if (reason === 'sw') {
      return (
        <div className='w-full flex flex-col  gap-4 justify-between items-center'>
          <div className='flex items-center gap-5 text-xs font-medium text-left'>
            <div>
              <p>REQ SW</p>
              <p className='mt-1 border p-1 w-[167px] text-left'>
                {requestData && requestData.requested_ship_week}
              </p>
            </div>
            <div>
              <p>Select New SW</p>
              <input
                type='text'
                className='border p-1 mt-1 text-xs w-[167px]'
                placeholder='SW.YY'
                value={inputsw}
                onChange={(e) => {
                  const value = e.target.value
                  setinputsw(value)

                  if (value.length === 5) {
                    const validation = validateShipWeekInput(
                      value,
                      requestData.requested_ship_week
                    )

                    if (!validation.isValid) {
                      setValidSw(true)
                      setValidationMessage(validation.message)
                    } else {
                      setValidSw(false)
                      setValidationMessage('')
                      const sw = getPossibleShipWeek(value)
                      setRequestSW(sw)
                      setDate(getDateofMonday(sw))
                      datePickerRef.current.setFocus()
                    }
                  } else {
                    setValidSw(false) // Reset message if input is incomplete
                    setValidationMessage('')
                  }
                }}
              />
            </div>
          </div>
          {/* above part */}
          <div class='h-px my-2 bg-gray-500 border-1 w-full ' />
          {/* below part */}
          <div className='flex items-center  gap-5 text-xs font-medium text-left'>
            <div>
              <p>REQ SW DATE</p>
              <p className='mt-1 border p-1 w-[167px] text-left'>
                {changeDateFormat(
                  requestData && requestData.requested_ship_week
                )}
              </p>
            </div>
            <div>
              <p>Select New SW Date</p>
              <DatePicker
                showWeekNumbers
                filterDate={(date) => date.getDay() === 1}
                selected={date}
                onChange={(date) => handleChangeDate(date)}
                className='p-1 mt-1 border text-xs'
                calendarStartDay={1}
                ref={datePickerRef}
                showYearDropdown
                minDate={getStartDate(
                  getPossibleShipWeek(requestData.requested_ship_week)
                )}
              />
            </div>
          </div>
          {validSw && <p className='text-red-500 test-xs'>Enter valid SW!</p>}
        </div>
      )
    } else {
      return (
        <div className='w-full flex flex-col gap-2 text-left'>
          <label htmlFor='sr_comment' className='text-xs font-medium'>
            {Comment}
          </label>
          <textarea
            id='sr_comment'
            className='w-full border border-gray-300 p-2 rounded-md text-xs'
            placeholder='Type here in detail...'
            onChange={(e) => setComment(e.target.value)}
          />
        </div>
      )
    }
  }

  const handleChangeDate = (date) => {
    setDate(date)
    setRequestSW(getWeek(date))
    setinputsw(getWeek(date))
  }

  const selectType = (event) => {
    setreasonCodeValue('')
    const value = event?.target?.value
    if (value === 'price') {
      setSecondReason(value)
      setreasonCodeValue(
        'If price discrepancy reach out to vendor manager via email'
      )
    } else {
      setSecondReason(value)
    }
  }
  return (
    <div className='w-full flx flex-col gap-2 justify-center items-center'>
      <div className='flex justify-center gap-12'>
        {' '}
        {/* Main container for horizontal alignment */}
        <div className='flex items-center justify-center gap-2'>
          {' '}
          {/* Container for the Accept button */}
          <button
            onClick={openConfirmModal}
            title='Accept'
            className='flex items-center justify-center p-2' // Centering within the button
          >
            <svg
              className='w-6 h-6 text-green-600'
              aria-hidden='true'
              xmlns='http://www.w3.org/2000/svg'
              fill='currentColor'
              viewBox='0 0 20 20'
            >
              <path d='M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z' />
            </svg>
            {/* <span className='ml-2 text-green-600'>Accept</span> */}
          </button>
        </div>
        <PopupModal
          isOpen={showConfirmModal}
          message={i18next.t('PopUpMessage.Doyouwanttoconfirmtheseorders')}
          onCancel={closeConfirmModal}
          cancelText={i18next.t('AcceptModal.Cancel')}
          onConfirm={handleConfirmPos}
          confirmText={i18next.t('AcceptModal.Confirm')}
        />
        <div className='flex items-center justify-center gap-2'>
          {' '}
          {/* Container for the Change Type button */}
          <button
            onClick={() => setShowRequestChangeModal(true)}
            title='ChangeType'
            className='flex items-center justify-center p-2' // Centering within the button
          >
            <svg
              className='w-6 h-6 text-white bg-black p-1 border border-slate-900 rounded-full font-bold'
              aria-hidden='true'
              xmlns='http://www.w3.org/2000/svg'
              fill='none'
              viewBox='0 0 24 24'
            >
              <path
                stroke='currentColor'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeWidth='3'
                d='M13.5 9.2 15 7h5m0 0-3-3m3 3-3 3M4 17h4l1.6-2.3M4 7h4l7 10h5m0 0-3 3m3-3-3-3'
              />
            </svg>
            {/* <span className='ml-2 text-white'>Change Type</span> */}
          </button>
        </div>
      </div>

      {/*Request Change Dialog Box */}
      <dialog
        open={showRequestChangeModal}
        className='p-3 inset-0 fixed z-50 bg-black bg-opacity-50 w-full min-h-screen'
      >
        <div className='w-full min-h-screen p-4 flex justify-center items-center'>
          {submitting ? (
            <div className='sm:w-[400px] w-[280px] bg-white p-4 rounded-md flex flex-col gap-6'>
              <Loading type={Submitting_load} />
            </div>
          ) : (
            <div className='sm:w-[400px] w-[280px] bg-white p-4 rounded-md flex flex-col gap-6'>
              <p className='w-full text-center'>
                {PleaseselectReasoncodefortheselectedorders}
              </p>

              {/* This is to show reasonCodeValue once user selected from list  */}
              <ReasonCodeView
                reasonCodeValue={reasonCodeValue}
                setreasonCodeValue={setreasonCodeValue}
              />
              {/* View End */}
              <div className='w-full flex flex-col gap-2 text-left'>
                <label htmlFor='change_type_dd' className='text-xs font-medium'>
                  {Changetype}
                </label>
                <select
                  onChange={selectType}
                  id='change_type_dd'
                  className='p-2 rounded-md outline-none border border-gray-300 text-xs w-full font-medium'
                  value={secondReason}
                >
                  <option value='' disabled>
                    {Selectatype}
                  </option>
                  {/* <option value='dc'>{DC}</option> */}
                  <option value='sw'>{Shipweek}</option>
                  <option value='price'>{Price}</option>
                  <option value='qty'>{Quantity}</option>
                </select>
              </div>
              {secondReason === 'price' && (
                <div className='w-full flex flex-col items-start gap-2'>
                  <label
                    htmlFor='reason_code_select'
                    className='text-xs font-medium'
                  >
                    {ReasonCode}
                  </label>
                  <select
                    onChange={(e) => setreasonCodeValue(e.target.value)}
                    name='reason_code_select'
                    id='reason_code_select'
                    className='p-2 rounded-md outline-none border border-gray-300 text-xs w-full font-medium'
                    value={reasonCodeValue}
                  >
                    <option
                      selected
                      value='If price discrepancy reach out to vendor manager via email'
                    >
                      If price discrepancy reach out to vendor manager via email
                    </option>
                  </select>
                </div>
              )}
              {/*  ReasonCode List Component to view all list and select*/}
              {secondReason?.length > 0 && secondReason !== 'price' && (
                //  ReasonCode List Component to view all list and select
                <ReasonCodeList
                  reasonCodeValue={reasonCodeValue}
                  setreasonCodeValue={setreasonCodeValue}
                  type={secondReason === 'qty' ? 'ConfirmQTY' : 'common'}
                />
              )}
              {/* End Component */}

              <div className='w-full'>
                {secondReason?.length > 0 &&
                  reasonCodeValue?.length > 0 &&
                  renderBlock(secondReason)}
              </div>
              <div className='w-full flex justify-center items-center gap-4 mt-4'>
                {secondReason &&
                  (secondReason === 'dc' || secondReason === 'sw' ? (
                    <button
                      className={`text-xs font-medium px-2 rounded-md h-[38px]  ${requestSW >= requestData.requested_ship_week ? 'cursor-not-allowed' : 'cursor-pointer'} text-white bg-[#ff9500] outline-none`}
                      onClick={editConfirm}
                    >
                      {/* {RequestChange} */}Request
                    </button>
                  ) : (
                    <button
                      className='text-xs font-medium px-2 rounded-md h-[38px] bg-[#ff9500]  outline-none text-white'
                      onClick={handleRejectPos}
                    >
                      {/* {RequestChange} */}Request
                    </button>
                  ))}
                <button
                  className='text-xs font-medium px-2 rounded-md h-[38px] text-white bg-gray-500 outline-none'
                  onClick={() => {
                    setShowRequestChangeModal(false)
                    setreasonCodeValue('')
                    setRequestSW('')
                    setSecondReason('')
                    setinputsw('')
                    setValidSw('')
                  }}
                >
                  {Cancel}
                </button>
              </div>
            </div>
          )}
        </div>
      </dialog>
      {/* <PopupBanner
        isOpen={showConfirmOrdersBanner}

        message={i18next.t('PopUpMessage.Changerequestissubmittedsuccessfully')}

        onCancel={closePopUpModal}
        text={editPoRow}
        text1={editCrIdResponse}
    
      /> */}
    </div>
  )
}

export default ConfirmActionComp
