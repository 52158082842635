import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import UserProfile from "../../components/UserProfile";
import { useForm } from "react-hook-form";
import BasicTable from "../../components/BasicTable";
import axios from "axios";
import EditableTable from "../../components/EditableTable";
import { ValidateAPI } from "../../utils/Utils";

const Configuration = () => {
  const [configureList, SetConfigureList] = useState([]);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const navigate = useNavigate();

  const onSubmit = (data) => {
    const payload = {
      configuration_identifier: data?.identifier,
      configuration_key: data?.countDays,
      configuration_value: data.countValue,
      enabled: data?.status,
    };
    console.log(payload);
    axios
      .post(
        `${process.env.REACT_APP_API_DOMAIN}:8088/api/admin/create-configuration`,
        payload,ValidateAPI
      )
      .then((res) => {
        if (res) {
          alert("Submitted Successfully");
          getAllConfigurationList();
        }
      })
      .catch((error) => {
        if(error.response.status===401 || error.response.status===403){
          navigate('/login-error')
        }
      });
  };
  const reportDateFormat = (inputDate) => {
    const date = new Date(inputDate);
    // Get day, month, and year from the Date object
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Month is zero-based
    const year = date.getFullYear();
    // Form the new date string in "ddmmyyyy" format
    const outputDateString = day + "/" + month + "/" + year;
    return outputDateString;
  };
  const EditDetails = ({ table, row, column }) => {
    // console.log(table,row.id,column);
    const setEditedRows = (e) => {
      // console.log(table?.options?.meta?.EditRowData)
      const elName = e.target.name;
      table?.options?.meta?.setEditRowData((old) => ({
        ...old,
        [row.id]: !old[row.id],
      }));
      if (elName !== "edit") {
        const editCOnfigData = table?.options?.meta?.revertData(
          row.index,
          e.target.name === "cancel"
        );
        if (editCOnfigData) UpdateCOnfiguration(editCOnfigData);
      }
    };
    // console.log(table?.options?.meta?.EditRowData);

    return table?.options?.meta?.EditRowData[row.id] ? (
      <>
        <button onClick={setEditedRows} name="cancel">
          X
        </button>{" "}
        <button onClick={setEditedRows} name="done">
          ✔
        </button>
      </>
    ) : (
      <button onClick={setEditedRows} name="edit">
        ✐
      </button>
    );
  };
  const UpdateCOnfiguration = (confiData) => {
    const payload = {
      configuration_id: confiData?.CONFIGURATION_ID,
      configuration_identifier: confiData?.CONFIGURATION_IDENTIFIER,
      configuration_key: confiData?.CONFIGURATION_KEY,
      configuration_value: confiData.CONFIGURATION_VALUE,
      enabled: confiData?.ENABLED,
    };
    console.log(payload);
    axios
      .put(
        `${process.env.REACT_APP_API_DOMAIN}:8088/api/admin/update-configuration`,
        payload,ValidateAPI
      )
      .then((res) => {
        if (res) {
          alert("Submitted Successfully");
        }
      })
      .catch((error) => {
        if(error.response.status===401 || error.response.status===403){
          navigate('/login-error')
        }
      });
  };
  const Status = ({ table, row, column, getValue }) => {
    const [value3, setValue3] = useState(getValue());
    const handleSend = (e) => {
      const val = e?.target?.value;
      setValue3(val);
      table?.options?.meta?.updateData(row?.index, column?.id, val);
    };
    return table?.options?.meta?.EditRowData[row?.id] ? (
      <select
        id="curr_qty"
        name="curr_qty"
        class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-sm focus:ring-blue-500 w-full "
        value={value3}
        onChange={handleSend}
      >
        <option value="Y">Enabled</option>
        <option value="N">Disbled</option>
      </select>
    ) : value3 === "Y" ? (
      <p>ENALBED</p>
    ) : (
      <p>DISABLED</p>
    );
  };
  const ChangeValue = ({ table, row, column, getValue }) => {
    const [value4, setValue4] = useState(getValue());
    const handleSend = (e) => {
      const val = e?.target?.value;
      table?.options?.meta?.updateData(row?.index, column?.id, value4);
    };
    return table?.options?.meta?.EditRowData[row?.id] ? (
      <input
        type="text"
        value={value4}
        className="w-full p-1 text-center"
        onChange={(e) => setValue4(e?.target?.value)}
        onBlur={handleSend}
      />
    ) : (
      value4
    );
  };
  const column = useMemo(() => [
    // { header: "Configure Id #", accessorKey: "CONFIGURATION_ID" },
    // {
    //   header: 'SL_NO',
    //   cell: (info) => info.row.index + 1,
     
    // },
    {
      header: "Identifier",
      accessorKey: "CONFIGURATION_IDENTIFIER",
    },
    {
      header: "KEY",
      accessorKey: "CONFIGURATION_KEY",
    },
    {
      header: "Value",
      accessorKey: "CONFIGURATION_VALUE",
      cell: (info) => <ChangeValue {...info} />,
    },
    {
      header: "Status",
      accessorKey: "ENABLED",
      cell: (info) => <Status {...info} />,
    },
    {
      header: "Created Date",
      accessorKey: "CREATEDAT",
      cell: (info) =>
        info?.getValue() !== null ? reportDateFormat(info?.getValue()) : "",
    },
    {
      header: "updated Date",
      accessorKey: "UPDATEDAT",
      cell: (info) =>
        info?.getValue() !== null ? reportDateFormat(info?.getValue()) : "",
    },
    {
      header: "Edit Deatils",
      cell: (info) => <EditDetails {...info} />,
    },
  ]);
  const getAllConfigurationList = () => {
    axios
      .get(
        `${process.env.REACT_APP_API_DOMAIN}:8088/api/admin/get-all-enabled-configurations`,ValidateAPI
      )
      .then((res) => {
        SetConfigureList(res?.data);
      })
      .catch((error) => {
        if(error.response.status===401 || error.response.status===403){
          navigate('/login-error')
        }
      });
  };
  useEffect(() => {
    getAllConfigurationList();
  }, []);
  return (
    <main className="p-2 w-full space-y-5">
      <div className="w-full justify-between flex items-center">
        <div className="">
          <p className="text-xl font-medium">Create Configuration</p>
        </div>
        {/* <UserProfile /> */}
      </div>
      {/* BreadCrumb */}
      <div className="w-full text-xs flex justify-start items-center gap-1 font-medium text-gray-600">
        <p className="flex gap-1 items-center justify-center">
          <span
            className="hover:underline cursor-pointer"
            onClick={() => navigate("/admin")}
          >
            Home
          </span>
          <span>{">"}</span>
        </p>
        <p className="flex gap-1 items-center justify-center">
          <span
            className="hover:underline cursor-pointer"
            onClick={() => navigate("/admin/configuration")}
          >
            Configuration
          </span>
          <span>{">"}</span>
        </p>

        <p className="flex gap-1 items-center justify-center">
          <span>Create New Configuration </span>
        </p>
      </div>
      <div className="m-auto max-w-md justify-center   ">
        <form onSubmit={handleSubmit(onSubmit)} className=" space-y-2">
          <div className="w-full flex gap-2">
            <div class=" w-1/2 group">
              <label
                for="Reconfirm"
                class="block  text-sm font-medium text-gray-900 dark:text-white  "
              >
                Configuration Identifier Name
              </label>
              <input
                type="text"
                {...register("identifier", { required: "Please Enter Value" })}
                class="bg-gray-50  border border-gray-300text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
              />
              <p className="text-sm text-red-600">
                {errors?.identifier?.message}
              </p>
            </div>
            <div class=" w-1/2 group">
              <label
                for="countDays"
                class="block  text-sm font-medium text-gray-900 dark:text-white"
              >
                Configuration Key
              </label>
              <input
                type="text"
                {...register("countDays", {
                  required: "Please Enter Message",
                })}
                class="bg-gray-50 border border-gray-300text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
              />
              <p className="text-sm text-red-600">
                {errors?.countDays?.message}
              </p>
            </div>
          </div>
          <div className="w-full flex gap-2">
            <div class=" w-1/2 group">
              <label
                for="countValue"
                class="block  text-sm font-medium text-gray-900 dark:text-white"
              >
                Configuration Value
              </label>
              <input
                type="text"
                {...register("countValue", {
                  required: "Please Enter Value",
                })}
                class="bg-gray-50 border border-gray-300text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
              />
              <p className="text-sm text-red-600">
                {errors?.countValue?.message}
              </p>
            </div>
            <div className=" w-1/2    flex items-center  gap-3">
              <label
                for="enabled"
                class="block  text-sm font-medium text-gray-900 dark:text-white"
              >
                Status
              </label>
              <input
                type="radio"
                {...register("status", { required: true })}
                value="Y"
                required
              />
              <label
                for="enabled"
                class="block  text-sm font-medium text-gray-900 dark:text-white"
              >
                Enable
              </label>
              <input
                type="radio"
                {...register("status", {
                  required: "please select any one of status",
                })}
                value="N"
                required
              />
              <label
                for="enabled"
                class="block  text-sm font-medium text-gray-900 dark:text-white"
              >
                Disable{" "}
              </label>
            </div>
          </div>
          <div className="text-center">
            <input
              type="submit"
              value="Submit"
              className="border p-2 rounded-md bg-gray-200 text-black cursor-pointer border-gray-500"
            />
          </div>
        </form>
      </div>
      {/* ALL HFT User TAble */}
      {configureList?.length !== 0 && (
        <EditableTable tableData={configureList} columns={column} />
      )}
    </main>
  );
};
export default Configuration;
